/****************************************************************************************************/
/*This file will control the javascript client side aspect of the whole front end of the application.*/
/*It will be included within the <head></head> of the the application which will facilitate caching  */
/*of the file preventing multiple http requests for javascript files. On each page of the app a      */
/*hidden field (<input type="hidden" value="page-name" name="c_page" />) will be included which will */
/*be used for recognizing which page the user is currently on. This initialization is done within    */
/*the document.ready function and the variable cPage is used to maintain which page the user is      */
/*currently on. Each Page will have it's designated set of fields which will be cached for optimized */
/*and efficient javascript and jquery functionality.                                                */
/*****************************************************************************************************/

/******** Main JS Variables***********************************************/
/*All variables and methods that will be common to all pages will be     */
/*declared here 	 													 */
/*************************************************************************/

var ajaxLocation = "/",
	pageUrl = null,
	validator  = null,
	mainNavigation = null,
	pageLoaded = false,
	baseSearchDiv = null,
	mainSearchLink = null,
	bedroomBathroomDiv = null,
	getTypesAjaxRequest = null,
	propertyTypeList = null,
	residentialCommercialRadio = null,
	priceFromText = null,
	priceToText = null,
	priceRangeSelect = null,
	keywordsText = null,
	bedList = null,
	bathList = null,	
	apiLoginAjaxRequest = "",
	emailAjaxRequest = "",
	getAjaxRequest = "",
	getCurrentPage = null,
	getPaginationDiv = null,
	getPaginationUl = null,
	logoutLink = null,
	mainContainer = null,
	spamFilter = null,	
	point = null,
	map,
	marker,
	myOptions,
	Zoom,
	lattitudeField = null,
	longitudeField = null,
	idSearch = null,
	furnishedUnfirnished = null,
	popStateCheck;
	
$(document).ready(function(){	

	$('input, textarea').placeholder();
	
	getTypesAjaxRequest 	= "";	
	propertyTypeList 		= $("#property_type" );
	baseSearchDiv 			= $("#base_search" );
	priceFromText 			= $("input[name='price_from']");
	priceToText 			= $("input[name='price_to']");
	priceRangeSelect 		= $("select[name='price']");
		
	rentSaleType			= $('#rent_sale_tab');
	rentSaleRadioName 		= $("select[name='rentsale']");
	
	parishList 				= $('#parish');
	townList 				= $('#town');
	
	keywordsText 			= $("#keywords");
	bedList 				= $("#bed");
	bathList 				= $("#bath");
	bedroomBathroomDiv 		= $('#bedroom_bathroom_div');
	idSearch 				= $("#id_search" );
	cPage 					= $('input[name="c_page"]').val();	
	furnishedUnfirnished 	= $("input[name='furnished']");
	pageCheck();

	residentialCommercialRadio 	= $('input[id="rescom"]');
	
	if($('select[name="category"]').length > 0){
		propertyTypeTab = $('select[name="category"]');
		propertyTypeTab.change(function(){	
			switch($(this).val()){
				case 'residential':
					residentialCommercialRadio.val('residential');
					searchResidentialCommercialChange(residentialCommercialRadio.val());
				break;
				case 'commercial':
					residentialCommercialRadio.val('commercial');
					searchResidentialCommercialChange(residentialCommercialRadio.val());
				break;
				default:
					residentialCommercialRadio.val('');
					searchResidentialCommercialChange('');
				break;
			}
		});	
	}
	
	if($('#property_type_tab').length > 0){
		propertyTypeTab = $('#property_type_tab');
		propertyTypeTab.delegate('li a', 'click', function(evt) {
			evt.preventDefault();
			var linkClick = $(this);
			var typeCall = null;
			$('#property_type_tab li').removeClass('active');
			linkClick.parent('li').addClass('active');
			switch(linkClick.html()){
				case 'Residential':
					residentialCommercialRadio.val('Residential');
					typeCall = "residential";
					rentSaleRadioName.val('both');
					searchResidentialCommercialChange(typeCall);
				break;
				case 'Commercial':
					residentialCommercialRadio.val('Commercial');
					typeCall = "commercial";
					rentSaleRadioName.val('both');
					searchResidentialCommercialChange(typeCall);
				break;
				case 'Short Term Rental':
					rentSaleRadioName.val('short');
					residentialCommercialRadio.val('Residential');
					typeCall = "residential";
					
					searchResidentialCommercialChange(typeCall);
					$('#property_type_tab dd:first-child').addClass('active');
				break;
				case 'Resort/Villa':
					rentSaleRadioName.val('both');
					residentialCommercialRadio.val('Residential');
					typeCall = "residential";
					
					searchResidentialCommercialChange(typeCall);
					
					
					setTimeout( function() {  propertyTypeList.val('Resort Apartment/Villa'); }, 1000);
					
					
				break;
				
				case 'Foreclosure':
					residentialCommercialRadio.val('foreclosure');
					typeCall = "";
					rentSaleRadioName.val('both');
					searchResidentialCommercialChange(typeCall);
				break;
				case 'Private Treaty':
					residentialCommercialRadio.val('private');
					typeCall = "";
					rentSaleRadioName.val('both');
					searchResidentialCommercialChange(typeCall);
				break;
			}		
		});
	}

	if($('#property_type_tab').length > 0 || $('select[name="category"]').length > 0){
		if(cPage !== "search" && cPage !== "details" && cPage !== "print" && cPage !== "sign_up"){
			searchResidentialCommercialChange(propertyTypeTab.val()); 
		}
	}

	if($('.property_details_slider').length){
		var detailsSlider =  $('.property_details_slider').bxSlider({
			pager:false,
			auto: true,
			onSlideAfter: function(){
			    // do funky JS stuff here
			    var slideQty = detailsSlider.getSlideCount();
			    var currentSlide = detailsSlider.getCurrentSlide();
			    
			    $('#current_val').text(currentSlide+1);
			    $('#total_val').text(slideQty);
			}
		});	
		var slideQty = detailsSlider.getSlideCount();
		
		var currentSlide = detailsSlider.getCurrentSlide();
			
		$('#current_val').text(currentSlide+1);
		$('#total_val').text(slideQty);
	}
	

	
	parishList.change(function(){
		var typesUrl = ajaxLocation+"ajax/resource/parish_town/"+parishList.val();
		if(getTypesAjaxRequest !== ""){
			getTypesAjaxRequest.abort();
		}
		getTypesAjaxRequest = $.ajax({
			type:'GET',
			url: typesUrl,
			dataType: "html"
		});
		getTypesAjaxRequest.then(
			function(data){	
				townList.html(data);
			},
			function(data){
			}
		);
	});
	
	$('#curr').change(function(){
		priceFromText.val('');
		priceToText.val('');
		var selectedRadio = $(rentSaleRadioName.selector + ":checked").val();
		var typesUrl = ajaxLocation+"ajax/resource/price_range/"+selectedRadio;
		if($(this).val() !== "" ){
			typesUrl += "/currency/" + $('#curr').val();
		}
		if(getTypesAjaxRequest !== ""){
			getTypesAjaxRequest.abort();
		}
		getTypesAjaxRequest = $.ajax({
			type:'GET',
			url: typesUrl,
			dataType: "html"
		});
		getTypesAjaxRequest.then(
			function(data){		
			
				$('#price').html(data)			
			},
			function(data){				
				
			}
		);
	});
	

	rentSaleRadioName.change(function(){
		priceFromText.val('');
		priceToText.val('');
		var typesUrl = ajaxLocation+"ajax/resource/price_range/"+$(this).val();
		if($('#curr').val() !== "" ){
			typesUrl += "/currency/" + $('#curr').val();
		}
		if(getTypesAjaxRequest !== ""){
			getTypesAjaxRequest.abort();
		}
		getTypesAjaxRequest = $.ajax({
			type:'GET',
			url: typesUrl,
			dataType: "html"
		});
		getTypesAjaxRequest.then(
			function(data){	
				$('#price').html(data)			
			},
			function(data){				
				
			}
		);
	});

	
	
	priceRangeSelect.delegate(priceRangeSelect, 'change', function(evt) {
		evt.preventDefault();
		searchPriceselected(priceRangeSelect.val());
	});
	
	resetForm();
	configureFacebookAPI();
	
	if($('.footer_slider').length > 0){
		$('.footer_slider').bxSlider({
			pager: false,
			auto: ($(".footer_slider li").length > 1) ? true: false ,
			autoHover: true,
			controls: false
		});
	}

	if($('#top-slider').length > 0){
		$("#top-slider").owlCarousel({
		    autoPlay: false,
		    stopOnHover: true,
		    nav: true,
		    navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
		    slideSpeed: 300,
		    pagination: false,
		    singleItem: true,
		    items:1
	  	});
	}

	if($('#featured-slider').length > 0){
	  	$("#featured-slider").owlCarousel({
		  	loop:true,
		    nav:true,
		    navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
		    autoplay:true,
		    autoplayTimeout: 3000,
		    autoplayHoverPause:true,
		    responsive:{
		        0:{
		            items:1
		        },
		        600:{
		            items:1
		        },
		        1000:{
		            items:1
		        }
		    }
		});
	}
	$('.close').click(function(){
    	$('.modal').modal('hide');
    });
});


function contactSearch(){	
	var property_mls = $('#AgentForm_mlsId');
	var property_pid = $('#AgentForm_propertyId');
	var agent_id = $('#AgentForm_agentId');
	var pid_val = "";
	var mls_val = "";
	var agent_id_val = "";

	$(document).on('click', '.agent_pop_up', function(e) { 
		pid_val = $(this).data("pid");
		mls_val = $(this).data("mls");
		agent_id_val = $(this).data("agent-id");
		property_mls.val(mls_val);
		property_pid.val(pid_val);
		agent_id.val(agent_id_val);

		spamFilter 		= $('#email_address_sent');
		agentForm 		= $('#contact_agent_form');
		agentSubmit 	= $('#submit_contact');		
		agentFirstName 	= $('#AgentForm_firstName');
		agentLastName 	= $('#AgentForm_lastName');
		agentEmail 		= $('#AgentForm_email_address');
		agentQuery 		= $('#AgentForm_agent_query');
		agentOptions	= $('input[name="AgentForm[contact_option]"]');
		emailAjaxRequest = "";

		validator = agentForm.validate();

		agentFirstName.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your First Name"
		     }
		});
		agentLastName.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your Last Name"
		     }
		});
		
		agentEmail.rules("add",{
			required:true,
			 email:"required",
			 messages: {
		           email: "Please Enter A Valid Email Address",
		           required:"Please Enter Your Email Address"
		     }
		});
			
		agentQuery.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your Query"
		     }
		});
			
		agentOptions.rules("add",{
			required:true,
			 messages: {
		            required: "Please Select an option"
		     }
		});

		$('#modal_button').click(function(){
			if($(this).text() === "Ok"){
				window.location.href = window.location.href;
			}
			else{
				$('#details_modal').modal('hide');
			}
		});
	});
	$(document).on('click', '#submit_contact', function(e) {		
		if(agentForm.valid() && spamFilter.val() === "" && (parseInt(pid_val) > 0 )  ){
			e.preventDefault();
			var slug = "Property";
			if(parseInt(mls_val) > 0){
				slug = slug + "-MLS-" + mls_val;
			}
			else{
				slug = "Property-" + pid_val;
			}
			sendContact('Search Agent',slug);

		}		
	});	
}

function contactAgentSearch(){
		var property_mls	= $('#contact_agent_form_2 AgentForm_mlsId');
		var property_pid 	= $('#contact_agent_form_2 #AgentForm_propertyId');
		var agent_id 		= $('#contact_agent_form_2 #AgentForm_agentId');
		var pid_val = "";
		var mls_val = "";
		var agent_id_val = "";
	
		pid_val = $(this).data("pid");
		mls_val = $(this).data("mls");
		agent_id_val = $(this).data("agent-id");
		property_mls.val(mls_val);
		property_pid.val(pid_val);
		agent_id.val(agent_id_val);
				
		if($('#contact_agent_form_2').length > 0){
			spamFilterA 		= $('#email_address_sent2');
			agentForm2 			= $('#contact_agent_form_2');
			agentSubmit 		= $('#submit_agent_contact');		
			agentFirstName 		= $('#first_name');
			agentLastName 		= $('#last_name');
			agentEmail 			= $('#email_address');
			agentQuery 			= $('#query_contact');
			agentOptions		= $('#contact_agent_form_2 input[name="AgentForm[contact_option]"]');
			emailAjaxRequest 	= "";
			
			mainPropertyContainer 	= $('.agent_profile');
			searchLoadingDiv 		= $('#loading_agent');

			validator2 = agentForm2.validate();
					
			agentFirstName.rules("add",{
				required:true,
				messages: {
					required: "Please Enter Your First Name"
				}
			});
			agentLastName.rules("add",{
								required:true,
								 messages: {
							            required: "Please Enter Your Last Name"
							     }
			});
			agentEmail.rules("add",{
				required:true,
				email:"required",
				messages: {
					email: "Please Enter A Valid Email Address",
					required:"Please Enter Your Email Address"
				}
			});
								
			agentQuery.rules("add",{
								required:true,
								 messages: {
							            required: "Please Enter Your Query"
							     }
			});
			agentOptions.rules("add",{
								required:true,
								 messages: {
							            required: "Please Select an option"
							     }
			});
							
			$('#modal_button').click(function(){
				if($(this).text() === "Ok"){
					window.location.href = window.location.href;
				}else{
					//$('#details_modal').modal('hide');
					$('#details_modal').modal('hide');
				}
				agentForm2.show();
			});
					
			$(document).on('click', '#submit_agent_contact', function(e) { 
				if(agentForm2.valid() && spamFilterA.val() === "" ){				
					e.preventDefault();
					sendContact('Contact Search Agent');
				}			
			});
		
		}
}

function shareSearch(){
	var pid_val = "";
	var mls_val = "";
	var agent_id_val = "";
	spamFilter 			= $('#email_address_sent');
	spamFilter2 		= $('#email_address_sent2');
	spamFilter3 		= $('#email_address_sent3');
	
	
	var property_mls = $('#ShareForm_mlsId');
	var property_pid = $('#ShareForm_propertyId');
	var agent_idHidden = $('#ShareForm_agentId');

	$(document).on('click', '.share_pop_up', function(e) {
		mls_val = 0;
		pid_val = 0;
		agent_id_val = 0;

		pid_val = $(this).data("pid");
		mls_val = $(this).data("mls");
		agent_id_val = $(this).data("agent-id");

		agent_idHidden.val(agent_id_val);
		
		
		shareForm = $('#share_form');
			shareValidator = shareForm.validate();
			shareForm.removeAttr("novalidate");	
			shareFirstName 	= $('#ShareForm_firstName');
			shareLastName 	= $('#ShareForm_lastName');	
			shareEmail 		= $('#ShareForm_emailAddress');
			shareFriendFullName = $('#ShareForm_friendsName');
			shareFriendEmail = $('#ShareForm_friendsEmail');
			shareComment = $('#ShareForm_comment');
			shareFirstName.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter Your First Name"
			     }
			});
			shareLastName.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter Your Last Name"
			     }
			});
			
			shareEmail.rules("add",{
				required:true,
				 email:"required",
				 messages: {
			           email: "Please Enter A Valid Email Address",
			           required:"Please Enter Your Email Address"
			     }
			});
		
			shareFriendFullName.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter Your Friend's Full Name"
			     }
			});
		
			shareFriendEmail.rules("add",{
				required:true,
				 email:"required",
				 messages: {
			           email: "Please Enter A Valid Email Address",
			           required:"Please Enter Your Friend's Email Address"
			     }
			});
			
			shareComment.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter A Comment"
			     }
			}); 
			
	});
	$(document).on('click', '#share_submit', function(e) {		
		if(shareForm.valid() && spamFilter2.val() === "" && (parseInt(pid_val) > 0 ) ){	
			e.preventDefault();
			var slug = "Property";
			if(parseInt(mls_val) > 0){
				slug = slug + "-MLS-" + mls_val;
			}
			else{
				slug = "Property-" + pid_val;
			}
			sendContact('Search Friend',slug);								
		}		
	});

	$('#modal_button').click(function(){
		if($(this).text() === "Ok"){
			$('#details_modal').modal('hide');
			window.location.href = window.location.href;
		}
		else{
			$('#details_modal').modal('hide');
		}
	});
}

function mapFooter(){
	lattitudeField 		= $('input[name="latitude_footer[]"]');
	longitudeField 		= $('input[name="longitude_footer[]"]');
	listField 			= $('input[name="locations_footer[]"]');

	mapDiv 				= $('#contact-map1');	
	mapDiv.show();
	Zoom = 14;
  	initializeFooter();
  	google.maps.event.trigger(map, 'resize');
}

function initializeFooter(){
	var jamaica = new google.maps.LatLng(18.081112, -77.426147);
	var lattitudeArray = lattitudeField;
	var longitudeArray = longitudeField;
	var locationArray = new Array();	
	if(listField !== null){
		listField.each(function(){
			locationArray.push($(this).val());
		});
	}	

	console.log(locationArray);

	if(Zoom == null){
		Zoom = 8;
	}

	if( (longitudeArray.length === 1 && lattitudeArray.length === 1 ) ){
		jamaica = new google.maps.LatLng(lattitudeField.val(), longitudeField.val());	
		if(Zoom == null){
			Zoom = 18;
		}
	}
	
	
	myOptions = {
		zoom: Zoom,
		scaleControl: false,
		scrollwheel: false,
		draggable: false,
		center: jamaica,
		mapTypeId: google.maps.MapTypeId.ROADMAP
	}	
  	map = new google.maps.Map(document.getElementById("contact-map1"), myOptions);
  	map.panBy(0, 250);

	for (var i = 0; i < longitudeArray.length; i++) {		
		var marker = new google.maps.Marker({
			position: new google.maps.LatLng(lattitudeArray.eq(i).val(),longitudeArray.eq(i).val()),
			map: map,
			title: 'Marker'
		});
		if(listField !== null){
			var infoWindow = new google.maps.InfoWindow(), marker, i;
    		google.maps.event.addListener(marker, 'click', (function(marker, i) {
    			return function() {
    				infoWindow.setContent(locationArray[i]);
    				infoWindow.open(map, marker);
    			}
    		})(marker, i));
    	}
    }
}

function mapSearch(){
	lattitudeField 		= $('input[name="lattitude"]');
	longitudeField 		= $('input[name="longitude"]');
	mapDiv 				= $('#mapping_canvas');	
	
	$(document).on('click', '.map_pop_up', function(e) { 
		mapDiv.html('');		
		var lattitudeA = $(this).data("lattitude");
		var longitudeA = $(this).data("longitude");
		
		lattitudeField.val(lattitudeA);
		longitudeField.val(longitudeA);
		
		mapDiv.slideToggle( "fast", function() {
			mapDiv.show();
			Zoom = 14;
		  	initialize();
		  	google.maps.event.trigger(map, 'resize');
		});		
	});
}
function configureContactIndex(){
	spamFilter = $('#email_address_sent');
	contactFirstName = $('#ContactForm_firstName');
	contactLastName = $('#ContactForm_lastName');
	contactCompanyName = $('#ContactForm_companyName');
	contactEmail = $('#ContactForm_email');
	contactTelephone = $('#ContactForm_telephone');
	contactSubject = $('#ContactForm_subject');
	contactComment = $('#ContactForm_comment');
	contactHear = $('#ContactForm_hearAboutUs');
	contactSubmit = $('#submit_contact_request');
	contactCountry = $('#ContactForm_country');
	contactLocation = $('#ContactForm_location');	

	contactForm = $('#agnet-send-message');	
	validator = contactForm.validate();
	searchLoadingDiv = $('#loading2');
	mainContainer = $('#contact_div');

	emailAjaxRequest = "";	
	
	contactFirstName.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your First Name"
         }
	});
	
	contactLastName.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your Last Name"
         }
	});
	contactEmail.rules("add",{
		 email:"required",
		 required:true,
		 messages: {
			 email: "Please Enter A Valid Email Address",
			 required:"Please Enter Your Email Address"
        }
	});
	contactComment.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your Message"
         }
	});


	contactSubmit.click(function(e){
		if(contactForm.valid() && spamFilter.val() === ""){			
			sendContact('Contact Index');
			e.preventDefault();
		}
	});

	$('#modal_button').click(function(){
		if($(this).html() === "Ok"){
			window.location.href = window.location.href;
		}else{
			$('#contact_modal').modal('show');
		}
	});

	mapFooter();
}
/****************************************************************************/
/*This function is executed when there is a change in Residential Commercial*/
/*Property Category in order to get property types exclusive to that 		*/
/*category. It utilizes ajax functionality in order to achieve this 		*/
/****************************************************************************/

function searchPriceselected(value){
	priceFromText 		= $("input[name='price_from']");
	priceToText 			= $("input[name='price_to']");
	
	priceFromText.val('');
	priceToText.val('');
	
	switch(value){
		case '0-5':	
			priceFromText.val('0');
			priceToText.val('5000');	
		break;
		case '5-10':	
			priceFromText.val('5000');
			priceToText.val('10000');		
		break;
		case '10-50':	
			priceFromText.val('5000');
			priceToText.val('10000');		
		break;
		case '50-100':	
			priceFromText.val('50000');
			priceToText.val('100000');			
		break;
		case '100-500':	
			priceFromText.val('100000');
			priceToText.val('500000');		
		break;
		case '500-999':	
			priceFromText.val('500000');
			priceToText.val('999000');		
		break;
		case '1-3M':	
			priceFromText.val('1000000');
			priceToText.val('3000000');		
		break;
		case '3-6M':	
			priceFromText.val('3000000');
			priceToText.val('6000000');		
		break;
		case '6-9M':	
			priceFromText.val('6000000');
			priceToText.val('9000000');		
		break;
		case '9-12M':	
			priceFromText.val('9000000');
			priceToText.val('12000000');		
		break;
		case '12-15M':	
			priceFromText.val('12000000');
			priceToText.val('15000000');	
		break;
		case '15_plus':	
			priceFromText.val('15000000');
			priceToText.val('');	
		break;
		default:	
			var arr = value.split('-');
			priceFromText.val(arr[0]);
			priceToText.val(arr[1]);
		break;
		
	}
}

function searchResidentialCommercialChange(value){	
	switch(value){
		case 'Residential':
		case 'residential':	
			bedroomBathroomDiv.show();		
		break;
		case 'Commercial':
		case 'commercial':
			bedroomBathroomDiv.hide();
			bedroomBathroomDiv.children().children().each(function(){
				$(this).val('');
			});		
		break;	
		default:
			value = "";
			bedroomBathroomDiv.hide();
			bedroomBathroomDiv.children().children().each(function(){
				bedroomBathroomDiv.show();	
			});	
		break;
	}	
	var typesUrl = ajaxLocation+"ajax/resource/propertytypes/"+value;
	if(getTypesAjaxRequest !== ""){
		getTypesAjaxRequest.abort();
	}
	
	
	getTypesAjaxRequest = $.ajax({
							type:'GET',
							url: typesUrl,
							dataType: "html"
						});
	getTypesAjaxRequest.then(
		function(data){				
			propertyTypeList.html(data)			
		},
		function(data){
		}
	); 
	
}

function isNumber(event) {
  if (event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 190 && charCode > 31 && 
       (charCode < 48 || charCode > 57) && 
       (charCode < 96 || charCode > 105) && 
       (charCode < 37 || charCode > 40) && 
        charCode != 110 && charCode != 8 && charCode != 46 )
       return false;
  }
  return true;
}



/****************************************************************************/
/*Ensures that the navigation bar maintains a fixed position on the screen  */
/*while scrolling on the site 												*/
/****************************************************************************/
function stickyNav(){
	if($('.nav').length){
		var stickyNavTop = $('.nav').offset().top;
		var stickyNav = function(){  
			var scrollTop = $(window).scrollTop();
			if (scrollTop > stickyNavTop) {   
				$('.nav').addClass('sticky');  
			} else {  
				$('.nav').removeClass('sticky');   
			}  
		};  
		stickyNav();  
		$(window).scroll(function() {  
			stickyNav();  
		}); 
	}
}

/****************************************************************************/
/*Checkes if an object is empty 											*/
/****************************************************************************/
function isEmptyObject(obj) {
	var name;
	for (name in obj) {
		return false;
	}
	return true;
}

/****************************************************************************/
/*Executes the API login of the application 								*/
/****************************************************************************/
function apiLogin(obj) {
	//searchStartStopLoading(true);
	apiLoginAjaxRequest = $.ajax({
									type:'POST',
									url: ajaxLocation + 'member',
									dataType: "json",
									data: obj,
								}
							);
	apiLoginAjaxRequest.then(
		function(data){		
			
			if(data.status === 200){
				window.location.href = window.location.href;
				/*searchStartStopLoading(false);				
				
				*/
			}
			else{
				/*
				$('#modal_header').html("Failed");
				$('#modal_paragraph').html(obj.sent_api + " Login Failed, Please Try Again or Refresh The Page");
				$('#modal_button').val("Ok");
				$('#member_modal').modal('show');
				*/
			}		
		},
		function(data){					
			/*searchStartStopLoading(false);
			$('#modal_header').html("Failed");
			$('#modal_paragraph').html(obj.sent_api + " Login Failed, Please Try Again or Refresh The Page");
			$('#modal_button').val("Ok");
			$('#member_modal').modal('show');
			*/
		}

	);	
}

/**************************************************************************/
/*This method is used for managing the history state of the browser from a*/
/*Javascript aspect. Based on the page the user is currently on, the      */
/*method will trigger the method used to manage events based on back and  */
/*forward browser button clicks for that specific page.					  */
/**************************************************************************/
onpopstate = function(event) {	
	if(pageLoaded){
		switch(cPage){
			case 'index':
			configureIndex();
			break;
			case 'search':			
			popStateSearchPage(event.state);
			break;			
			case 'developments':			
			popStateDevelopmentPage(event.state);
			break;
			case 'agents':				
			popStateAgentPage(event.state);
			break;		
			case 'faqs':				
			popStateFaqsPage(event.state);	
			case 'tips':
			popStateTipsPage(event.state);
			break;	
			case 'news':
			popStateNewsPage(event.state);
			break;
			case 'favourite':
			popStateFavouritePage(event.state);
			break;	
			case 'gallery':
			popStateGalleryPage(event.state);
			break;	
		}
	}
	pageLoaded = true;
	popStateCheck = 1;					
}

/*************************************************************************/
/*Function used to check which page the user is currently on. Based on   */
/*the current page, a configure function will be triggered which will    */
/*initialize all variables exclsuive to that page.						 */
/*************************************************************************/
function pageCheck(){
	console.log(cPage);	
	switch(cPage){
		case 'index':
			configureIndex();
			break;
		case 'search':	
			searchColor();	
			configureSearch();	
			
			contactSearch();
			contactAgentSearch();
			mapSearch();
			shareSearch();			
			break;
		case 'contact':		
			searchColor();	
			configureContact();
			break;
		case 'developments':
			searchColor();			
			configureDevelopments();
			break;		
		case 'agents':		
			searchColor();
			configureAgents();
			break;	
		case 'faqs':
			configureFaqs();
		break;
		case 'details':		
			searchColor();					
			configureDetails();
			fbDetails();	
			img_loop();	
			initialize();		
			break;	
		case 'pages':
			searchColor();
			break;	
		case 'about-pages':
			gotoJamaica();
			break;	
		case 'list-property':
			searchColor();
			configureListMyProperty();
			break;	
		case 'find-property':
			configureFindProperty();
		break;
		case 'vacancy':
			configureVacancy();
		break;
		case 'news':
			configureNews();
		break;
		case 'gallery':
			configureGallery();
		break;
		case 'member':
			configureMembers();
		break;
		case 'sign_up':
			configureSignUp();
		break;
		case 'profile':
			configureEditProfile();
		break;	
		case 'tips':
			configureTips();
		break;	
		case 'forget':
			configureForgetPassword();
		break;
		case 'reset_password':
			configureResetPassword();
		break;
		case 'favourite':
			configureFavouriteProperties();
		break;
		case 'print':
			configurePrint();
		break;
		case 'event':
			configureEvent();
		break;
		case 'unsubscribe':
		configureUnsubscribe();
		break;
		case 'update_profile':
		configureUpdateProfile();
		break;
	}
}

var delay = (function(){
  	var timer = 0;
  	return function(callback, ms){
    	clearTimeout (timer);
    	timer = setTimeout(callback, ms);
  	};
})();

function popOpenNotice(){
	var max_screen_width = 910;
	if($('#openHouse_modal').length > 0){
		
		
		delay(function(){
			var width 	= $(window).width();
			var height 	= $(window).height();
				
			if(parseInt(width) < max_screen_width){	
				$('#openHouse_modal').modal('hide');
				$('.reveal-modal-bg').css({
					"display": "none ",
					"background":"rgba(3, 45, 71, 0)",
					"height": "0%",
					"width": "0%"
				});		
				$('.reveal-modal-bg').hide();		
			}else{	
				
				$('#openHouse_modal').modal('show');	
				$('.reveal-modal-bg').css({
					"display": "block !important",
					"background":"rgba(3, 45, 71, 0.9)",
					"height": "100%",
					"left": "0",
					"width": "100%",
					"position": "fixed",
					"top": "0"
				});	
				$('.reveal-modal-bg').show();			
			}
		}, 500);
		
		
		
		
		$(window).resize(function() {
			delay(function(){
				var width 	= $(window).width();
				var height 	= $(window).height();
				
				if(parseInt(width) < max_screen_width){	
					$('#openHouse_modal').modal('hide');
					
					$('.reveal-modal-bg').css({
						"display": "none",
						"background":"rgba(3, 45, 71, 0)",
						"height": "0%",
						"width": "0%"
					});	
					$('.reveal-modal-bg').hide();
					
				}else{		
					
					$('#openHouse_modal').modal('show');
						
					$('.reveal-modal-bg').css({
						"display": "block",
						"background":"rgba(3, 45, 71, 0.9)",
						"height": "100%",
						"left": "0",
						"width": "100%",
						"position": "fixed",
						"top": "0"
					});	
					$('.reveal-modal-bg').show();
					
				}
			}, 1000);			
		});
		
		
		if($('.openhouse_slider').length){				 
			$('.openhouse_slider').bxSlider({
				mode: 'fade',
				pager: false,
				auto: true,
				autoHover: true,
				minSlides: 1,
				maxSlides: 1,
				infiniteLoop: false,
				nextSelector: '.rightbutton',
				prevSelector: '.leftbutton',
				nextText: 'NEXT',
				prevText: 'BACK'
			});	
		}
		
		
		var slideli = $('.openHousePop').width()+ " !important ";
		
		$('#modal_paragraph ul li').css('width', slideli);
		
	}

	if($('#openHouse_modal0').length > 0){
		var width = $(window).width();
		var height = $(window).height();
			
		if(parseInt(width) >= max_screen_width){	
			$('#openHouse_modal0').modal('show');	
			$('.reveal-modal-bg').removeAttr('style');	
			$('.reveal-modal-bg').attr('style','display: block !important;background: rgba(3, 45, 71, 0.9) !important;height: 100%;left: 0;position: fixed;top: 0;');	
		}else{
			$('#openHouse_modal0').modal('show');	
			$('.reveal-modal-bg').attr('style','display: block !important;background: rgba(3, 45, 71, 0.9) !important;height: 100%;left: 0;position: fixed;top: 0;');
		}	
		
		$(window).resize(function() {
			var width = $(window).width();
			var height = $(window).height();		
				
			if(parseInt(width) <= max_screen_width){	
				$('#openHouse_modal0').modal('hide');
				$('.reveal-modal-bg').removeAttr('style');
				$('.reveal-modal-bg').attr('style','display: none !important;');	
			}else{
				$('#openHouse_modal0').modal('show');	
				$('.reveal-modal-bg').attr('style','display: block !important;background: rgba(3, 45, 71, 0.9) !important;height: 100%;left: 0;position: fixed;top: 0;');
			}
		});			
	}	
}


function searchColor(){
	$(".red_bar").hide();
	keywordsText.addClass("red");
}

var photoLink = null,
	locationLink = null,
	imageDiv = null,
	mapDiv = null,
	mapStatus = 0;
	
function configurePrint(){
	var spamFilter2 = null, spamFilter3 = null;
	var slider = $('.report_property_slider').bxSlider({
		pager:false,
		auto: ($(".report_property_slider li").length > 1) ? false: false ,
		controls: ($(".report_property_slider li").length > 1) ? true: false,
		speed: 1590,
		pause: 10000,
		preloadImages : 'all',
		useCSS: false,
		easing: 'linear',
		onSlideAfter: function(){
			// do funky JS stuff here
		  var slideQty = slider.getSlideCount();
		  var currentSlide = slider.getCurrentSlide();
		
		  $('#current_val').text(currentSlide+1);
		  $('#total_val').text(slideQty);
		 }	            
	});
	var slideQty = slider.getSlideCount();
	var currentSlide = slider.getCurrentSlide();
	
	 $('#current_val').text(currentSlide+1);
	 $('#total_val').text(slideQty);
	
	 $('.bx-prev , .bx-next, .bx-pager-item').on('click',function(){
	 	slider.stopAuto();
	  slider.startAuto();            
	 });
	 
	
	$(".various1").fancybox({
		autoSize	: true,
		closeClick	: false,
		openEffect	: 'none',
		closeEffect	: 'none'
	});

	$('#print_page').click(function(){
		window.print();
	});
	
	locationLink 		= $('#location_link');
	locationSpan		= $('#location_span');
	imageDiv 			= $('#image_div');
	mapDiv 					= $('#mapping_canvas');	
	lattitudeField 	= $('input[name="lattitude"]');
	longitudeField 	= $('input[name="longitude"]');
	Zoom						= 14;
	
	locationLink.click(function(e){
		e.preventDefault();		
		if(locationLink.data('hide') === 0){
			mapDiv.show();
			if(mapStatus === 0){
				initialize();
				mapStatus = 1;
			}
			imageDiv.hide();		
			locationSpan.html('Hide Map');
			locationLink.data('hide',1);
		}
		else if(locationLink.data('hide') === 1){
			locationLink.data('hide',0);
			mapDiv.hide();
			imageDiv.show();
			locationSpan.html('Map');						
		}
	});
	
	if($('#share_form').length > 0){
	
		spamFilter 			= $('#email_address_sent');
		spamFilter2 		= $('#email_address_sent2');
		spamFilter3 		= $('#email_address_sent3');
		
		shareForm = $('#share_form');
		shareValidator = shareForm.validate();
		shareForm.removeAttr("novalidate");	
		shareFirstName 	= $('#ShareForm_firstName');	
		shareLastName 	= $('#ShareForm_lastName');	
		shareEmail 		= $('#ShareForm_emailAddress');
		shareFriendFullName = $('#ShareForm_friendsName');
		shareFriendEmail = $('#ShareForm_friendsEmail');
		shareComment = $('#ShareForm_comment');
		
		shareFirstName.rules("add",{
			required:true,
			messages: {
				required: "Please Enter Your First Name"
			}
		});
		shareLastName.rules("add",{
			required:true,
			messages: {
				required: "Please Enter Your First Name"
			}
		});
			
		shareEmail.rules("add",{
				required:true,
				 email:"required",
				 messages: {
			           email: "Please Enter A Valid Email Address",
			           required:"Please Enter Your Email Address"
			     }
			});
		shareFriendFullName.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter Your Friend's Full Name"
			     }
			});
		shareFriendEmail.rules("add",{
				required:true,
				 email:"required",
				 messages: {
			           email: "Please Enter A Valid Email Address",
			           required:"Please Enter Your Friend's Email Address"
			     }
			});
		shareComment.rules("add",{
				required:true,
				 messages: {
			            required: "Please Enter A Comment"
			     }
			});  
			
		$(document).on('click', '#share_submit', function(e) {
			if(shareForm.valid() && spamFilter2.val() === ""){				
				sendContact('Friend');
				e.preventDefault();
			}
		});
	}
}

function configureEvent(){
	$(".eventpop").fancybox({
		openEffect	: 'none',
		closeEffect	: 'none',
	    	helpers : {
	    		title : {
	    			type : 'over'
	    		},
	    		overlay : {
	    			locked: false,
	    			css : {
	    				'background' : 'rgba(0, 0, 0, 0.95)'
	    			}
	    		}
	    		
	    	}
	});
	
	
	$(document).on('click', '.scrollpage', function(e) {	
		var a_href = $(this).attr('href');
	    $('html, body').animate({
	        scrollTop: a_href.offset().top
	    }, 2000);
	});
	
	
	$(document).on('click', '.reserve_event', function(e) {		
		var boxClass = $(this).data('value');
		
		if($(this).prop("checked") == true){
			$('.eventbox.'+boxClass+'').removeClass('closed');
			$('.eventbox.'+boxClass+'').addClass('open');
			
			$('.party.'+boxClass+'').removeClass('closed');
			$('.party.'+boxClass+'').addClass('open');
					
		}else if($(this).prop("checked") == false){
			$('.eventbox.'+boxClass+'').removeClass('open');
			$('.eventbox.'+boxClass+'').addClass('closed');
			
			$('.party.'+boxClass+'').removeClass('open');
			$('.party.'+boxClass+'').addClass('closed');
		}
		$(document).foundation();
		
	});

	spamFilter = $('#email_address_sent');
	contactTitle = $('#EventForm_title');	
	contactFirstName = $('#EventForm_firstName');
	contactLastName = $('#EventForm_lastName');
	contactEmail = $('#EventForm_email');
	contactAttend = $('input[name="attend"]');	
	contactSubmit = $('#submit_form');
	contactForm = $('#event-form');	
	contactEvent = $('input[name="reserve_event[]"]');	
	validator = contactForm.validate();	
	
	// searchLoadingDiv = $('#loading');
	// mainContainer = $('#contact_div');
	
	emailAjaxRequest = "";

	if(contactTitle.length > 0){
		contactTitle.rules("add",{
			required:true,
			 messages: {
	                required: "Please Select Your Title"
	         }
		});
	}
	if(contactFirstName.length > 0){
		contactFirstName.rules("add",{
			required:true,
			 messages: {
	                required: "Please Enter Your First Name"
	         }
		});
	}
	
	if(contactLastName.length > 0){
		contactLastName.rules("add",{
			required:true,
			 messages: {
	                required: "Please Enter Your Last Name"
	         }
		});
	}
	if(contactEmail.length > 0){
		contactEmail.rules("add",{
			 email:"required",
			 required:true,
			 messages: {
				 email: "Please Enter A Valid Email Address",
				 required:"Please Enter Your Email Address"
	        }
		});
	}
	if(contactAttend.length > 0){
		contactAttend.rules("add",{
			required:true,
			 messages: {
	                required: "Please Select A Value"
	         }
		});
	}
	if(contactEvent.length > 0){
		contactEvent.rules("add",{
			required:true,
			 messages: {
	                required: "Please Select an Event"
	         }
		});
	}

	contactSubmit.click(function(e){		
		if(contactForm.valid() && spamFilter.val() === ""){			
			sendContact('Contact');
			e.preventDefault();
		}
	});

	/*$('#modal_button').click(function(){
		if($(this).text() === "Ok"){
			window.location.href = window.location.href;
		}
		else{
			$('#contact_modal').modal('hide');
		}
	});
	*/

	initialize();
}


/*************************************************************************/
/*Resets The Elements Within The Search Form Of The application 		 */
/*************************************************************************/
function resetForm(){
	var resetButton 			= $('#main_search #reset_button');
	var residentialRadio 	= $('#main_search #residential');
	var rentSaleRadio 		= $('#main_search #rentsaleboth');
	var rentSaleSelect 		= $('#main_search #rentsale');
	var categoryRadio 		= $('#main_search #all_radio');
	var currencyRadio 		= $('#main_search #currAll');
	var currencySelect 		= $('#main_search #curr');
	var parishSelect 		= $('#main_search #parish');
	var rescomInput			= $('#main_search #rescom');
	var propertyTypeList 	= $("select[name='category']" );
	
	resetButton.click(function(e){
		e.preventDefault();
		priceFromText.val("");	
		priceToText.val("");	
		priceToText.val("");	
		priceRangeSelect.val("");
		
		propertyTypeList.val("");	
		keywordsText.val("");
		bedList.val("");
		bathList.val("");				
		parishSelect.val('');
		residentialRadio.prop("checked", true);
		rescomInput.val("");
		rentSaleRadio.prop("checked", true);
		rentSaleSelect.val("both");
		
		currencyRadio.prop("checked", true);
		currencySelect.val('all');
		propertyTypeList.val('');
		categoryRadio.prop("checked", true);
		furnishedUnfirnished.prop("checked", false);
		idSearch.val('');
		searchResidentialCommercialChange(residentialRadio.val());
	});	
}

function img_loop() {	
	var property_list_string = '';
	var propertyArray = [];
    $('#property-d-1 .owl-stage-outer .owl-stage .owl-item .item img').each(function() {
		propertyArray.push({
      		href: $(this).attr('src')
   		});        
    });

    if (propertyArray.length === 0) {

	}else{

		$("#photo_link, #photo_link2").click(function(evt) {
			evt.preventDefault();
			console.log('right here!');
			$.fancybox.open( propertyArray , {
				helpers : {
					thumbs : {
						width: 75,
						height: 50
					},
					overlay : {
						locked: false,
						css : {
							'background' : 'rgba(0, 0, 0, 0.95)'
						}
					}		
				},
				type: "image",
				beforeShow: function () {
					var newWidth = this.width * 2;
					var newHeight = this.height * 2;
					
					$(".fancybox-image").css({
						"width": newWidth,
						"height": newHeight
					});
					this.width = newWidth;
					this.height = newHeight;
				}
			});
		});	
	}
}

/*************************************************************************/
/*Executes The Google MAP API functionality with info window pop ups and */
/*markers		 														 */
/*************************************************************************/
function initialize() {	
	/*
	var jamaica = new google.maps.LatLng(18.081112, -77.426147);
	var lattitudeArray = lattitudeField;
	var longitudeArray = longitudeField;
	var locationArray = new Array();	
	if(listField !== null){
		listField.each(function(){
			locationArray.push($(this).val());
		});
	}	
	if(Zoom == null){
		Zoom = 8;
	}
	
	if( (longitudeArray.length === 1 && lattitudeArray.length === 1 ) ){
		jamaica = new google.maps.LatLng(lattitudeField.val(), longitudeField.val());	
		if(Zoom == null){
			Zoom = 18;
		}
	}
	
	
	myOptions = {
		zoom: Zoom,
		center: jamaica,
		mapTypeId: google.maps.MapTypeId.ROADMAP
	}	
  	map = new google.maps.Map(document.getElementById("mapping_canvas"), myOptions);
	for (var i = 0; i < longitudeArray.length; i++) {		
		var marker = new google.maps.Marker({
			position: new google.maps.LatLng(lattitudeArray.eq(i).val(),longitudeArray.eq(i).val()),
			map: map,
			title: 'Marker'
		});
		if(listField !== null){
			var infoWindow = new google.maps.InfoWindow(), marker, i;
    		google.maps.event.addListener(marker, 'click', (function(marker, i) {
    			return function() {
    				infoWindow.setContent(locationArray[i]);
    				infoWindow.open(map, marker);
    			}
    		})(marker, i));
    	}
    }
    */
}

/*************************************************************************/
/*This Sends Emails From within the application via ajax requests. Based */
/*on the page the user is currently on the from parameter dictates which */
/*email should be sent 													 */
/*************************************************************************/
function sendContact(from,slug){
	var contactUrl = ajaxLocation;	
	switch(from){
		case 'Contact':
			searchStartStopLoading(true);
			contactUrl = window.location.href;
			emailAjaxRequest = $.ajax({
				type:'POST',
				url: contactUrl,
				dataType: "json",
				data: contactForm.serializeArray(),
			});
			emailAjaxRequest.then(
				function(data){			
					if(parseInt(data.status) === 200){
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#contact_modal').removeClass('failed');
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#modal_button').text("Try Again");
						$('#contact_modal').addClass('failed');
					}					
					searchStartStopLoading(false);
					//$('#contact_modal').modal('show');	
					$('#contact_modal').modal('show'); 			
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				},
				function(data){				
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#modal_button').text("Try Again");
					$('#contact_modal').addClass('failed');								
					searchStartStopLoading(false);		
					
					//$('#contact_modal').modal('show');
					$('#contact_modal').modal('show'); 


					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				}
			); 
		break;
		case 'Contact Index':
			searchStartStopLoading(true);
			contactUrl = window.location.href;
			emailAjaxRequest = $.ajax({
										type:'POST',
										url: contactUrl,
										dataType: "json",
										data: contactForm.serializeArray(),
									}
								);
			emailAjaxRequest.then(
				function(data){		
					console.log(data);
					if(parseInt(data.status) === 200){
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#contact_modal').removeClass('failed');
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#modal_button').text("Try Again");
						$('#contact_modal').addClass('failed');
					}					
					searchStartStopLoading(false);
					$('#contact_modal').modal('show');
					//$('#contact_modal').modal('show');				
					//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				},
				function(data){		
					
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#modal_button').text("Try Again");
					$('#contact_modal').addClass('failed');
									
					searchStartStopLoading(false);		
					$('#contact_modal').modal('show');
					//$('#contact_modal').modal('show');
					//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
				}
		); 
		break;
		case 'Contact Search Agent':
			mainPropertyContainer.fadeOut('fast');
			searchLoadingDiv.fadeIn('slow');
			
			contactUrl = window.location.href;			
			emailAjaxRequest = $.ajax({
							type:'POST',
							url: contactUrl,
							dataType: "json",
							data: agentForm2.serializeArray(),
						});

			emailAjaxRequest.then(
				function(data){		
					if(parseInt(data.status) === 200){
						agentForm2.trigger("reset");;
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#details_modal').removeClass('failed');
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#modal_button').text("Try Again");
						$('#details_modal').addClass('failed');
					}	
					//$('#details_modal').modal('show');
					$('#details_modal').modal('show'); 
					//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					
					
					searchLoadingDiv.fadeOut('fast');
					mainPropertyContainer.fadeIn('slow');
				},
				function(data){
					
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#modal_button').text("Try Again");
					$('#details_modal').addClass('failed');
					
					//$('#details_modal').modal('show');
					$('#details_modal').modal('show');
					//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchLoadingDiv.fadeOut('fast');
					mainPropertyContainer.fadeIn('slow');
				}
			);
		break;	
		case 'Agent':
			searchStartStopLoading(true);
			contactUrl = window.location.href;		
			emailAjaxRequest = $.ajax({
										type:'POST',
										url: contactUrl,
										dataType: "json",
										data:  agentForm.serializeArray(),
									}
								);
			emailAjaxRequest.then(
				function(data){	
				
					if(parseInt(data.status) === 200){
						agentForm.trigger("reset");;
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#details_modal').removeClass('failed');
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#modal_button').text("Try Again");
						$('#details_modal').addClass('failed');
					}	
					//ƒ$('#details_modal').modal('show');
					$('#details_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchStartStopLoading(false);
				},
				function(data){
					
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#modal_button').text("Try Again");
					$('#details_modal').addClass('failed');
					
					//$('#details_modal').modal('show');
					$('#details_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchStartStopLoading(false);
				}
			);
		break;
		case 'Mortgage':
			searchStartStopLoading(true);		
			contactUrl = window.location.href;		
			emailAjaxRequest = $.ajax({
				type:'POST',
				url: contactUrl,
				dataType: "json",
				data:  mortgageForm.serializeArray(),
			}
			);
			emailAjaxRequest.then(
				function(data){		

					if(parseInt(data.status) === 200){
						mortgageForm.trigger("reset");;
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");	
						$('#details_modal').removeClass('failed');				
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#details_modal').addClass('failed');
					}	
					$('#details_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchStartStopLoading(false);
				},
				function(data){
					
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#details_modal').addClass('failed');
					
					$('#details_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchStartStopLoading(false);	
				}
			);		
		break;
		case 'Friend':
			toggleDetailsScreens();
			searchStartStopLoading(true);		
			contactUrl = window.location.href;		
			emailAjaxRequest = $.ajax({
										type:'POST',
										url: contactUrl,
										dataType: "json",
										data:  shareForm.serializeArray(),
									}
								);
			emailAjaxRequest.then(
				function(data){		
				
					if(parseInt(data.status) === 200){
						shareForm.trigger("reset");;
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");	
						$('#modal_button').text("Ok");	
						$('#details_modal').removeClass('failed');				
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#modal_button').text("Try Again");
						$('#details_modal').addClass('failed');
					}	
					//$('#details_modal').modal('show');
					$('#details_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchStartStopLoading(false);
				},
				function(data){
					
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#modal_button').text("Try Again");
					$('#details_modal').addClass('failed');
					
					//$('#details_modal').modal('show');
					$('#details_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchStartStopLoading(false);	
				}
			);		
		break;
		case 'Search Agent':
			$('#contact_pop').modal('hide');	
			mainPropertyContainer.fadeOut('fast');
			searchLoadingDiv.fadeIn('slow');
			contactUrl = ajaxLocation + 'property/' + slug;	
			emailAjaxRequest = $.ajax({
										type:'POST',
										url: contactUrl,
										dataType: "json",
										data:  agentForm.serializeArray(),
									}
								);
			emailAjaxRequest.then(
				function(data){		
					
					if(parseInt(data.status) === 200){
						agentForm.trigger("reset");;
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#details_modal').removeClass('failed');
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#modal_button').text("Try Again");
						$('#details_modal').addClass('failed');
					}	
					//$('#details_modal').modal('show');
					$('#details_modal').modal('show');
					//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchLoadingDiv.fadeOut('fast');
					mainPropertyContainer.fadeIn('slow');
				},
				function(data){		
					
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#modal_button').text("Try Again");
					$('#details_modal').addClass('failed');
					
					//$('#details_modal').modal('show');
					$('#details_modal').modal('show');
					//$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchLoadingDiv.fadeOut('fast');
					mainPropertyContainer.fadeIn('slow');
				}
			);	
		break;
		case 'Search Friend':	
			//$('#share_pop').modal('hide');	
			$('#share_pop').modal('hide');
			mainPropertyContainer.fadeOut('fast');
			searchLoadingDiv.fadeIn('slow');	
			contactUrl = ajaxLocation + 'property/' + slug;		
			emailAjaxRequest = $.ajax({
										type:'POST',
										url: contactUrl,
										dataType: "json",
										data:  shareForm.serializeArray(),
									}
								);
			emailAjaxRequest.then(
				function(data){			
				
					if(parseInt(data.status) === 200){
						shareForm.trigger("reset");;
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");	
						$('#modal_button').text("Ok");	
						$('#details_modal').removeClass('failed');				
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#modal_button').text("Try Again");
						$('#details_modal').addClass('failed');
					}	
					$('#details_modal').modal('show');
					// $('html,body').animate({scrollTop: $('body').offset().top}, 500);
					
					searchLoadingDiv.fadeOut('fast');
					mainPropertyContainer.fadeIn('slow');
					//getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
				},
				function(data){
					
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#modal_button').text("Try Again");
					$('#details_modal').addClass('failed');
					$('#share_pop').modal('hide');
					$('#details_modal').modal('show');
					// $('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchLoadingDiv.fadeOut('fast');
					mainPropertyContainer.fadeIn('slow');
					//getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
				}
			);		
		break;
		case 'Request':
			toggleDetailsScreens();
			searchStartStopLoading(true);
			contactUrl = window.location.href;
			emailAjaxRequest = $.ajax({
										type:'POST',
										url: contactUrl,
										dataType: "json",
										data:  requestForm.serializeArray(),
									}
								);
			emailAjaxRequest.then(
				function(data){		
							
					if(parseInt(data.status) === 200){
						requestForm.trigger("reset");;
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#details_modal').removeClass('failed');
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#modal_button').text("Try Again");
						$('#details_modal').addClass('failed');
					}	
					$('#details_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchStartStopLoading(false);
				},
				function(data){
					
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#modal_button').text("Try Again");
					$('#details_modal').addClass('failed');
					$('#details_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchStartStopLoading(false);	
				}
			);	
		break;
		case 'List':
			searchStartStopLoading(true);
			contactUrl = window.location.href;
			emailAjaxRequest = $.ajax({
										type:'POST',
										url: contactUrl,
										dataType: "json",
										data:  listForm.serializeArray(),
									}
								);
			emailAjaxRequest.then(
				function(data){		
					
					if(parseInt(data.status) === 200){
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#list_modal').removeClass('failed');
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#modal_button').text("Try Again");
						$('#list_modal').addClass('failed');
					}	
					$('#list_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					
					searchStartStopLoading(false);
				},
				function(data){
					
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#modal_button').text("Try Again");
					$('#list_modal').addClass('failed');
					
					$('#list_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchStartStopLoading(false);	
				}
			);	
		break;
		case 'Find':
			searchStartStopLoading(true);
			contactUrl = window.location.href;
			emailAjaxRequest = $.ajax({
										type:'POST',
										url: contactUrl,
										dataType: "json",
										data:  findForm.serializeArray(),
									}
								);
			emailAjaxRequest.then(
				function(data){	
					
					if(parseInt(data.status) === 200){
						$('#modal_header').html("Sent");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#find_modal').removeClass('failed');
					}
					else{
						$('#modal_header').html("Failed");
						$('#modal_paragraph').html(data.message);
						$('#modal_button').val("Try Again");
						$('#modal_button').text("Try Again");
						$('#find_modal').addClass('failed');
					}	
					$('#find_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchStartStopLoading(false);
				},
				function(data){
					
					$('#modal_header').html("Failed");
					$('#modal_paragraph').html(data.message);
					$('#modal_button').val("Try Again");
					$('#modal_button').text("Try Again");
					$('#list_modal').addClass('failed');
					
					$('#find_modal').modal('show');
					$('html,body').animate({scrollTop: $('body').offset().top}, 500);
					searchStartStopLoading(false);
				}
			);	
		break;
		default :
		break;
	}	
}

/**************************************************************************/
/*This method initializes and uninitializes the loading process for pages */
/**************************************************************************/
function searchStartStopLoading(startStop){
	if(startStop){
		if(mainPropertyContainer !== null){
			mainPropertyContainer.html('&nbsp;');	
			mainPropertyContainer.fadeOut('fast');
		}		
		if(mainContainer !== null){
			mainContainer.fadeOut('fast');	
		}		
		searchLoadingDiv.fadeIn('slow');
	}
	else{		
		searchLoadingDiv.fadeOut('fast');	
		if(mainPropertyContainer !== null){
			mainPropertyContainer.fadeIn('slow');
		}		
		if(mainContainer !== null){
			mainContainer.fadeIn('slow');	
		}		
	}		
}

function configurePagination(){	
	getPaginationDiv = $('.pagination');
	getPaginationUl = $('.pagination .ul_pager');
	
	if($('.pagination ul').length > 0){
		$('.pagination ul').addClass('clearfix');		
	}	
	
	getPaginationUl.delegate('li a', 'click', function(evt) {		
		evt.preventDefault();		
     	switch($(this).html().trim()){
     		case '‹ First':
     		getCurrentPage = 1;     		
     		break;
     		case '« Previous':
     		if(getCurrentPage !== 1 && getCurrentPage !== 0){
     			getCurrentPage--;
     		}     		
     		break;
     		case 'Next ›':
     		if(getCurrentPage !== parseInt($('input[name="number_pages"]').val())){
     			getCurrentPage++;
     		}  
     		break;
     		case 'Last »':
     		getCurrentPage = parseInt($('input[name="number_pages"]').val());
     		break;
     		default:
     		getCurrentPage = parseInt($(this).html().trim());
     		break;     		
     	} 
     	$("html, body").animate({ scrollTop: $('.middleHeader').offset().top }, 500);
     	
     	switch(cPage){     		
			case 'search':		
				getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);	
								
			break;
			case 'developments':
			getAjaxRequest = developmentPagination(getCurrentPage,getAjaxRequest,false);		
			break;		
			case 'agents':
			getAjaxRequest = agentsPagination(getCurrentPage,getAjaxRequest,false);				
			break;	
			case 'news':
			getAjaxRequest = newsPagination(getCurrentPage,getAjaxRequest,false);
			break;
			case 'tips':
			getAjaxRequest = tipsPagination(getCurrentPage,getAjaxRequest,false);
			break;	
			case 'favourite':
			getAjaxRequest = favouritePagination(getCurrentPage,getAjaxRequest,false);
			break;
			case 'gallery':
			getAjaxRequest = galleryPagination(getCurrentPage,getAjaxRequest,false);
			break;
		}   
    });
}

/**************************************************************************/
/*Number Formats In Javascript to get , between each thousand 			  */
/**************************************************************************/
function formatNumber(num, decpoint, sep) {
	if (arguments.length == 2) {
		sep = ",";
	}
	if (arguments.length == 1) {
		sep = ",";
		decpoint = ".";
	}

	num = num.toString();  
  	a = num.split(decpoint);
  	x = a[0];
  	y = a[1];
  	z = "";
  	if (typeof(x) != "undefined") {
  		for (i=x.length-1;i>=0;i--)
  			z += x.charAt(i);
  		z = z.replace(/(\d{3})/g, "$1" + sep);
  		if (z.slice(-sep.length) == sep)
  			z = z.slice(0, -sep.length);
  		x = "";
  		for (i=z.length-1;i>=0;i--)
  			x += z.charAt(i);
  		if (typeof(y) != "undefined" && y.length > 0)
  			x += decpoint + y;
  	}
  	return x;
}

function GetBkImage() {
   return 
}

/*************************************************************************/
/*Configures the landing page of the application.    					 */
/*************************************************************************/
function configureIndex(){	
	$("#single-item").owlCarousel({
	    autoPlay: true,
	    navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
	    stopOnHover: true,
	    pagination: true,
	    paginationNumbers: true,
	    nav: false,
	    singleItem:true,
	    items: 1
	});
	$("#four-item").owlCarousel({
	    autoPlay: true,
	    navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
	    stopOnHover: true,
	    pagination: false,
	    paginationNumbers: false,
	    nav: true,
	    margin: 15,
	    items: 3,
	    responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1199: {
                items: 3
            }
        }
	});

	$("#seven-item").owlCarousel({
	    autoPlay: true,
	    navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
	    stopOnHover: true,
	    pagination: false,
	    paginationNumbers: false,
	    nav: true,
	    autoWidth:true,
	    items: 8,
	    responsive: {
            0: {
                items: 2
            },
            979: {
                items: 4
            },
            1199: {
                items: 8
            }
        }
	});


}

/*************************************************************************/

/********Variables && Methods Exclusive To The Contact Page **************/
var contactFirstName = null,
	contactLastName = null,
	contactCompanyName = null,
	contactEmail = null,
	contactTelephone = null,
	contactSubject = null,
	contactComment = null,
	contactHear = null,
	contactSubmit = null,
	contactForm = null,	
	listField = null;

/*************************************************************************/
/*Configures the contact page of the application. 				         */
/*************************************************************************/
function configureContact(){	
	$('body').removeClass('main_index');
	Zoom = 18;
	spamFilter = $('#email_address_sent');
	contactFirstName = $('#ContactForm_firstName');
	contactLastName = $('#ContactForm_lastName');
	contactCompanyName = $('#ContactForm_companyName');
	contactEmail = $('#ContactForm_email');
	contactTelephone = $('#ContactForm_telephone');
	contactSubject = $('#ContactForm_subject');
	contactComment = $('#ContactForm_comment');
	contactHear = $('#ContactForm_hearAboutUs');
	contactSubmit = $('#submit_contact_request');
	contactForm = $('#contact-form');	
	validator = contactForm.validate();
	lattitudeField = $('input[name="latitude[]"]');
	longitudeField = $('input[name="longitude[]"]');
	listField = $('input[name="locations[]"]');
	searchLoadingDiv = $('#loading');
	mainContainer = $('#contact_div');
	emailAjaxRequest = "";

	contactFirstName.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your First Name"
         }
	});
	contactLastName.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your Last Name"
         }
	});
	contactEmail.rules("add",{
		 email:"required",
		 required:true,
		 messages: {
			 email: "Please Enter A Valid Email Address",
			 required:"Please Enter Your Email Address"
        }
	});
	contactComment.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your Message"
         }
	});
	contactHear.rules("add",{
		required:true,
		 messages: {
                required: "Please Select An Option For How You Heard Of Us"
         }
	});

	contactSubmit.click(function(e){		
		if(contactForm.valid() && spamFilter.val() === ""){			
			sendContact('Contact');
			e.preventDefault();
		}
	});

	$('#modal_button').click(function(){
		if($(this).text() === "Ok"){
			window.location.href = window.location.href;
		}
		else{
			$('#contact_modal').modal('hide');
		}
	});

	initialize();	
}
/*************************************************************************/

/*************************************************************************/
/*Configures the vacancy page of the application. 				         */
/*************************************************************************/
var vacancyForm = null;
function configureVacancy(){
	vacancyForm 		= $('#vacancy-form');	
	vacancyForm.validate();
	//validator 			= vacancyForm.validate();

	vacancyFirstName 	= $('#VacancyForm_firstName');
	vacancyLastName 	= $('#VacancyForm_lastName');
	vacancyEmail		= $('#VacancyForm_email');
	vacancyRealEstate	= $('input[name="VacancyForm[utech]"]');
	vacancyMotorCar		= $('input[name="VacancyForm[motorcar]"]');
	vacancyUpload		= $('input[name="VacancyForm[resumeUpload]"]');
	vacancychk_send		= $('#VacancyForm_chk_send');
	
	vacancySubmit		= $('#submit_vacancy_request');
	
	$('#VacancyForm_utech_1').prop("checked", true);
	$('#VacancyForm_motorcar_1').prop("checked", true);
	
	vacancyFirstName.rules("add",{
		required:true,
		 messages: {
	            required: "Please Enter Your First Name"
	     }
	});
	
	vacancyLastName.rules("add",{
		required:true,
		 messages: {
	            required: "Please Enter Your Last Name"
	     }
	});
	
	vacancyEmail.rules("add",{
		 email:"required",
		 required:true,
		 messages: {
			 email: "Please Enter A Valid Email Address",
			 required:"Please Enter Your Email Address"
	    }
	});
	
	vacancyRealEstate.rules("add",{
		required:true,
		messages: {
	    	required: "Please Select an option"
	    }
	});
	
	vacancyMotorCar.rules("add",{
		required:true,
		messages: {
	    	required: "Please Select an option"
	    }
	});
	vacancyUpload.rules("add",{
		required:true,
		messages: {
	    	required: "Please upload your resume in a PDF format"
	    }
	});
	vacancychk_send.rules("add",{
		required:true,
		messages: {
	    	required: "Please Select an option"
	    }
	});
	
	vacancySubmit.click(function(e){
		$('#motorcar_error').html('');
		$('#utech_error').html('');

		var continueApplication;
		if($('input[name="VacancyForm[motorcar]"]:checked').val() === 'No'){
			$('#motorcar_error').html('<label class="error1" style="color: #f04124;">Must have a reliable Motor Car to qualify</label>');
			continueApplication = 1;
		}
		if($('input[name="VacancyForm[utech]"]:checked').val() === 'No'){
			$('#utech_error').html('<label class="error1" style="color: #f04124;">Must have passed course to qualify</label>');
			$('#utech_error label').show();
			continueApplication = 1;
		}

		if(vacancyForm.valid()){
			if(continueApplication === 1){
				e.preventDefault();
			}	
		}
	});
}
/*************************************************************************/

/********Variables && Methods Exclusive To The Details Page **************/
var photoLink = null,
	locationLink = null,
	imageDiv = null,
	mapDiv = null,
	mapStatus = 0,
	actionDiv = null,
	shareActionDiv = null,
	showingActionDiv = null,
	shareForm = null,
	shareValidator = null,
	requestValidator = null,
	requestForm = null,
	shareFriendLink = null,
	exposeMask = null,
	datePicker = null,
	locationSpan = null,
	videoSpan = null,
	savePropertyLink = null,
	savedTitle = null,
	savedImg = null,
	spamFilter2 = null,
	spamFilter3 = null,
	spamFilter4 = null,
	savePropertyAjaxRequest = "";


function configureDetails(){	
//	$('.addToolTip').tooltip();
	
	spamFilter 			= $('#email_address_sent');
	spamFilter2 		= $('#email_address_sent2');
	spamFilter3 		= $('#email_address_sent3');
	spamFilter4 		= $('#email_address_sent4');
	datePicker 			= $('#RequestForm_requestDateTime');
	/*
	datePicker.datepicker({
	      showOn: "button",
	      buttonImage: ajaxLocation+"images/calendar.png",
	      buttonImageOnly: true,
	      buttonText: "Select date",
	      dateFormat: 'yy-mm-dd'
	});	
	*/
	
	searchLoadingDiv 	= $('#loading');
	mainContainer 		= $('#details_div');
	photoLink 			= $('#photo_link');
	locationLink 		= $('#location_link');
	locationSpan		= $('#location_span');	
	videoSpan			= $('#video_span');	
	imageDiv 			= $('#image_div');
	mapDiv 				= $('#mapping_canvas');	
	lattitudeField 		= $('input[name="lattitude"]');
	longitudeField 		= $('input[name="longitude"]');	
	playerDiv 			= $('#main_player');
	videoLink			= $('#video_link');
	calculatorLink		= $(".mortglink");
	backLink			= $('#back_link');	
	savePropertyLink 	= $('#save_property');
	savedTitle 			= $('#saved_title');
	saveImg 			= $('#save_property img');
	video_prop	 		= $('input[name="video_id"]').val();
	
	mortgageCall 		= $('.mortgageCalculator > a');
	mortgageBlock 		= $('#nms_calculator_pop');
	mortgageMail 		= $('#mortgageFormBlock');

	var video_status = videoLink.data('status');

	backLink.click(function(e){
		e.preventDefault();
		//mapDiv.hide();
		//$('#main_player').css("display","none");
		//imageDiv.show();
	});
	
	photoLink.click(function(e){
		e.preventDefault();
		//mapDiv.hide();
		$('#main_player').css("display","none");
		imageDiv.show();
		videoLink.data('hide',0);
		locationLink.data('hide',0);
		locationSpan.html('Map');
		videoSpan.html('VIDEO');
		mapDiv.hide();
		if(video_prop != null && videoLink.data('status') == 'played'){
			stopVideo();
		}
	});

	
	locationLink.click(function(e){
		e.preventDefault();		
		if(locationLink.data('hide') === 0){
			mapDiv.show();
			if(mapStatus === 0){
				initialize();
				mapStatus = 1;
			}
			imageDiv.hide();
			playerDiv.hide();locationLink.data('hide',1);
			videoLink.data('hide',0);			
			locationSpan.html('Hide Map');
			videoSpan.html('VIDEO');
			$('#main_player').css("display","none");
		}
		else if(locationLink.data('hide') === 1){
			locationLink.data('hide',0);
			mapDiv.hide();
			imageDiv.show();
			locationSpan.html('Map');					
			if(video_prop != null && videoLink.data('status') == 'played'){
				stopVideo();
			}	
		}
	});

	videoLink.click(function(e){		
		e.preventDefault();	
		
		if(videoLink.data('hide') === 0){
			$('#main_player').removeAttr("style");			
			
			if(mapStatus === 0){
				initialize();
				mapStatus = 1;
			}

			imageDiv.hide();
			mapDiv.hide();
			videoLink.data('status','played');
			videoLink.data('hide',1);
			locationLink.data('hide',0);		
			videoSpan.html('HIDE VIDEO');
			locationSpan.html('Map');			
			exposeMask.css({"position":"fixed", "top":"0","left": "0","width": "100%","height": "100%", "display": "block", "opacity":" 0.7", "z-index":"9998","background-color": "#002c48"});			
			$('#main_player').css({"position":"relative","color":"#fff","z-index":"9999"});			
			playerDiv.show();
			callVideo();
		}
		else if(videoLink.data('hide') === 1){
			videoLink.data('hide',0);
			mapDiv.hide();
			playerDiv.hide();
			imageDiv.show();
			videoSpan.html('VIDEO');
			if(video_prop != null && videoLink.data('status') == 'played'){
				stopVideo();
			}			
		}
	});	
	
	calculatorLink.click(function(e){
		videoLink.data('hide',0);
		mapDiv.hide();
		playerDiv.hide();
		imageDiv.show();
		videoSpan.html('VIDEO');
		stopVideo();
	});
	
	$('#close_video').click(function(e){
		exposeMask.removeAttr("style");
		$('#main_player').removeAttr("style");		
		videoLink.data('hide',0);
		//mapDiv.hide();
		playerDiv.hide();
		imageDiv.show();
		videoSpan.html('VIDEO');	
		stopVideo();
	});	

	Zoom = 14;
	
	/*if($('#mortgage_form').length > 0){		
		mortgageForm 				= $('#mortgage_form');
		mortgageValidator 			= mortgageForm.validate();
		mortgageForm.removeAttr("novalidate");	
		mortgageFirstName 			= $('#MortgageForm_firstName');	
		mortgageLastName 			= $('#MortgageForm_lastName');	
		mortgageEmail 				= $('#MortgageForm_email_address');
		mortgageComment 			= $('#MortgageForm_comment');
		mortgageChoice 				= $('input[name="mortgageChoice"]');
		
		mortgageFirstName.rules("add",{
			required:true,
			messages: {
				required: "Please Enter Your First Name"
			}
		});
		mortgageLastName.rules("add",{
			required:true,
			messages: {
				required: "Please Enter Your Last Name"
			}
		});
		mortgageEmail.rules("add",{
			required:true,
			email:"required",
			messages: {
				email: "Please Enter A Valid Email Address",
				required:"Please Enter Your Email Address"
			}
		});
		mortgageComment.rules("add",{
			required:true,
			messages: {
				required: "Please Enter A Comment"
			}
		});
		mortgageChoice.rules("add",{
			required:true,
			messages: {
				required: "Please Select a Mortgagee"
			}
		});
		$(document).on('change', 'input[name="mortgageChoice"]', function(e) {
			
			dataCompany 				= $(this).data('company');
			mortgageRateHidden 			= $('input[name="mortgage_calculator_rate[]"]');
			monthlySpan 				= $('.mortgage_monthly_span');
			loanSpan 					= $('.mortgage_loan_span');	
			mortgage_period 			= $('.mortgage_period');

			$('#preferred_mortgagee').val($(this).data('company'));
			$('#preferred_monthly_payment').val($('.mortTable .monthly_payment[data-company="'+dataCompany+'"]').val());
			$('#preferred_rate').val($('.mortTable .mortgagerate[data-company="'+dataCompany+'"]').val());

			if($('input[name=mortgageChoice]:checked')){
				mortgageMail.show();	
				mortgageMail.data('hide', 0);   	
			}else{
				mortgageMail.hide();	
				mortgageMail.data('hide', 1);		
			}
			
		});

		$(document).on('click', '.cancel-mortgage-form', function(e) {
			e.preventDefault();
			mortgageMail.hide();
			mortgageMail.data('hide', 1);
			$('input[name=mortgageChoice]').attr('checked', false);
		});


		$(document).on('click', '#submit_mortgage', function(e) {
			if(mortgageForm.valid() && spamFilter4.val() === ""){	
				$('.erroBlock').hide();			
				sendContact('Mortgage');
				e.preventDefault();
			}else{
				if($('#mortgageChoice-error').text() !== null){
					$('.erroBlock').html($('#mortgageChoice-error').text());
					$('.erroBlock').addClass('red');
					$('.erroBlock').show();

				}
			}
		});
		
	}*/

	if($('#contact_agent_form').length > 0){
		agentForm 		= $('#contact_agent_form');
		agentSubmit 	= $('#submit_contact');		
		agentFirstName 	= $('#AgentForm_firstName');
		agentLastName 	= $('#AgentForm_lastName');
		agentEmail 		= $('#AgentForm_email_address');
		agentQuery 		= $('#AgentForm_agent_query');
		agentOptions	= $('input[name="AgentForm[contact_option]"]');
		emailAjaxRequest = "";
		validator = agentForm.validate();

		agentFirstName.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your First Name"
		     }
		});
		agentLastName.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your Last Name"
		     }
		});
		
		agentEmail.rules("add",{
			required:true,
			 email:"required",
			 messages: {
		           email: "Please Enter A Valid Email Address",
		           required:"Please Enter Your Email Address"
		     }
		});
		
		agentQuery.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your Query"
		     }
		});
		
		agentOptions.rules("add",{
			required:true,
			 messages: {
		            required: "Please Select an option"
		     }
		});
		
		agentSubmit.click(function(e){
			if(agentForm.valid() && spamFilter.val() === ""){			
				sendContact('Agent');
				e.preventDefault();
			}
		});
	}
	
	actionDiv = $('#contact_action_div');
	shareActionDiv = $('#share_action_div');
	showingActionDiv = $('#showing_action_div');
//	actionDiv.show();
//	shareActionDiv.hide();
//	showingActionDiv.hide();
//	exposeMask = $('#exposeMask');
	
	
	if($('#share_form').length > 0){
		shareForm = $('#share_form');
		shareValidator = shareForm.validate();
		shareForm.removeAttr("novalidate");	
		shareFirstName 			= $('#ShareForm_firstName');
		//shareFullName				= $('#ShareForm_fullName');
		shareLastName 			= $('#ShareForm_lastName');	
		shareEmail 					= $('#ShareForm_emailAddress');
		shareFriendFullName = $('#ShareForm_friendsName');
		shareFriendEmail 		= $('#ShareForm_friendsEmail');
		shareComment 				= $('#ShareForm_comment');

		shareFirstName.rules("add",{
			required:true,
			messages: {
				required: "Please Enter Your First Name"
			}
		});
		shareLastName.rules("add",{
			required:true,
			messages: {
				required: "Please Enter Your Last Name"
			}
		});
		// shareFullName.rules("add",{
		// 	required:true,
		// 	messages: {
		// 		required: "Please Enter Your Full Name"
		// 	}
		// });
		
		shareEmail.rules("add",{
			required:true,
			 email:"required",
			 messages: {
		           email: "Please Enter A Valid Email Address",
		           required:"Please Enter Your Email Address"
		     }
		});
		shareFriendFullName.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your Friend's Full Name"
		     }
		});
		shareFriendEmail.rules("add",{
			required:true,
			 email:"required",
			 messages: {
		           email: "Please Enter A Valid Email Address",
		           required:"Please Enter Your Friend's Email Address"
		     }
		});
		shareComment.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter A Comment"
		     }
		});        	
	}
	
	if($('#showing_form').length > 0){
		requestForm = $('#showing_form');
		requestValidator = requestForm.validate();
		requestForm.removeAttr("novalidate");
		requestFirstName 	= $('#RequestForm_firstName');
		requestLastName 	= $('#RequestForm_lastName');	
		requestEmail 	= $('#RequestForm_email');	
		requestTelephone		= $('#RequestForm_telephone');
		requestDateTime = $('#RequestForm_requestDateTime');
		requestComment = $('#RequestForm_comment');	
		requestFirstName.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your First Name"
		     }
		});
		requestLastName.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your Last Name"
		     }
		});
		requestEmail.rules("add",{
			required:true,
			 email:"required",
			 messages: {
		           email: "Please Enter A Valid Email Address",
		           required:"Please Enter Your Email Address"
		     }
		});
		requestTelephone.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Your Telephone Number"
		     }
		});
		requestDateTime.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter Select A Date And Time"
		     }
		});
		requestComment.rules("add",{
			required:true,
			 messages: {
		            required: "Please Enter A Comment"
		     }
		});
	}

	$(document).on('click', '#share_friend_click', function(e) {
		e.preventDefault();
		var clickShare = $(this).data("clicked");

		if(clickShare == 0){
//			actionDiv.hide('fast');
//			shareActionDiv.show();
//			shareImageSrc = shareImageSrc.replace("share_friend.png", "contact_agent.png");
//			shareImage.attr('src', shareImageSrc);
//			$('#share_friend span').html('Contact Agent');
			$(this).data("clicked", 1);
		}else{
//			shareActionDiv.hide('fast');
//			actionDiv.show();
//			shareImageSrc = shareImageSrc.replace("contact_agent.png", "share_friend.png");
//			shareImage.attr('src', shareImageSrc);
//			$('#share_friend span').html('Share with a friend');
			$(this).data("clicked", 0);
		}
		
		
//		exposeMask.css({"position":"fixed", "top":"0","left": "0","width": "100%","height": "100%", "display": "block", "opacity":" 0.7", "z-index":"9998","background-color": "#002c48"});
//		$('#share_friend').css({"z-index":"9999","position":"relative","color":"#fff"});
		
	});

	$(document).on('click', '#share_submit', function(e) {
		e.preventDefault();
		if(shareForm.valid() && spamFilter2.val() === ""){				
			console.log(1);
			sendContact('Friend');
			e.preventDefault();
		}
		console.log(shareForm.valid());
		console.log(spamFilter2.val());
	});
	
	
	$(document).on('click', '#request_showing', function(e) {
		e.preventDefault();
		actionDiv.hide('fast');
		showingActionDiv.show();
	});
	
	
	$(document).on('click', '#showing_submit', function(e) {
		if(requestForm.valid()){				
			sendContact('Request');
			e.preventDefault();
		}
	});	

	$(document).on('click', '#close_red_box', function(e) {
		toggleDetailsScreens();		
	});

	savePropertyLink.click(function(e){
		e.preventDefault();
		var image_src = saveImg.attr('src');
		var favouriteUrl = ajaxLocation+"ajax-favourite/property_id/"+savePropertyLink.data("property-id")+"/mls/"+savePropertyLink.data("mls")+"/saved/"+savePropertyLink.data("saved");

		savePropertyAjaxRequest = $.ajax({
									type:'POST',
									url: favouriteUrl,
									dataType: "json",									
								}
							);
		savePropertyAjaxRequest.then(
			function(data){
				if(data.status === "200"){
					if( savePropertyLink.data("saved") === 0 ){
						savePropertyLink.data("saved",1);
						savedTitle.html("Remove Favourite");
						image_src = image_src.replace("save_property.png", "save_property_red.png");
						saveImg.attr('src', image_src);
						$('#modal_header').html("Saved");
						$('#modal_paragraph').html("Favourite Was Saved Successfully");
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#details_modal').removeClass('failed');
					}
					else{
						savePropertyLink.data("saved",0);
						savedTitle.html("Add to Favourite");
						image_src = image_src.replace("_red", "");
						saveImg.attr('src', image_src);
						$('#modal_header').html("Removed");
						$('#modal_paragraph').html("Favourite Was Removed Successfully");
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#details_modal').removeClass('failed');
					}
				}
				else{
					if( savePropertyLink.data("saved") === 0 ){
						$('#modal_header').html("Error");
						$('#modal_paragraph').html("Favourite Was Not Saved, Please Try Again");
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#details_modal').addClass('failed');
					}
					else{
						$('#modal_header').html("Error");
						$('#modal_paragraph').html("Favourite Was Not Removed, Please Try Again");
						$('#modal_button').val("Ok");
						$('#modal_button').text("Ok");
						$('#details_modal').addClass('failed');
					}
				}	
				$('#details_modal').modal('show');		
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);				
			},
			function(data){
				//$('#details_modal').addClass('failed');
			}
		); 
	});

	$('#modal_button').click(function(){
		$('#details_modal').modal('hide');
		$('html,body').animate({scrollTop: $('body').offset().top}, 500);
	});
	
	mortgageRateHidden 			= $('input[name="mortgage_calculator_rate[]"]');
	monthlySpan 				= $('.mortgage_monthly_span');
	monthlyPayment 				= $('.monthly_payment');	
	loanSpan 					= $('.mortgage_loan_span');	
	mortgage_period 			= $('.mortgage_period');

	homePrice 					= $('#home_price');
	downPayment 				= $('#down_payment');
	termsYears 					= $('#terms_years');	

	mortgage_period.html(termsYears.val()+" Yrs");

	downPayment.keyup(function(){
		mortgageCalculator();
		mortgage_period.html(termsYears.val()+" Yrs");
	});
	termsYears.keyup(function(){
		mortgageCalculator();
		mortgage_period.html(termsYears.val()+" Yrs");
	});
}

function mortgageCalculator(){
	mortgageRateHidden.each(function(){
		var principle;
		var price = homePrice.val();
		var downInterestPayment = downPayment.val();

		var interestRate  = $(this).val();
		var yearlyInterestRate;
		var months;
		var dataCompany = $(this).data('company');
		var loanAmount = "";

		principle = price - downInterestPayment;
		yearlyInterestRate = (interestRate/100) / 12;
		months = parseInt(termsYears.val()) * 12;

		loanAmount = parseInt(price) - parseInt(downInterestPayment);		
		formatCurrency(Math.floor((principle*yearlyInterestRate)/(1-Math.pow(1+yearlyInterestRate,(-1*months)))*100)/100,monthlySpan.filter('[data-company="'+dataCompany+'"]'));
		formatCurrency(Math.floor((principle*yearlyInterestRate)/(1-Math.pow(1+yearlyInterestRate,(-1*months)))*100)/100,monthlyPayment.filter('[data-company="'+dataCompany+'"]'))
		formatCurrency(loanAmount,loanSpan.filter('[data-company="'+dataCompany+'"]'));
	});
}

function formatCurrency(num, val) {
	num = num.toString().replace(/\$|\,/g,'');	
	if(isNaN(num))
		num = "0";
	sign = (num == (num = Math.abs(num)));
	num = Math.floor(num*100+0.50000000001);
	cents = num%100;
	num = Math.floor(num/100).toString();	
	if(cents<10)
		cents = "0" + cents;
	for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++)
		num = num.substring(0,num.length-(4*i+3))+','+
	num.substring(num.length-(4*i+3));
	if(val.is('input')){
		val.val((((sign)?'':'-') + '$' + num + '.' + cents));
	}else{
		val.html((((sign)?'':'-') + '$' + num + '.' + cents));
	}

}
function fbDetails(){	
	var property_image = $('.property_details_slider li:first-child .image_contraints img').attr('src');
	var property_title = $("input[name='site_tt']").val();
	
	$("meta[property='og:image']").attr("content", property_image);
	$("meta[property='og:title']").attr("content", property_title);
	
	$('#facebookapi_share').html("<fb:share-button href='"+window.location.href+"' type='button_count'></fb:share-button>");
	//FB.XFBML.parse(document.getElementById('facebookapi_share')); 
	    
}

function toggleDetailsScreens(){
	actionDiv.show();
	shareActionDiv.hide();	
	showingActionDiv.hide();
	if(exposeMask !== null && exposeMask !== undefined){
		exposeMask.removeAttr("style");
	}
	
	$('#share_friend').removeAttr("style");
	$('#request_showing').removeAttr("style");
}

/*************************************************************************/

var faqKeyword 	= null,
	faqCategory = null;
		
function configureFaqs(){
	getCurrentPage 			= $('#current_page').val();
	mainPropertyContainer 	= $('#main_faq_container');
	searchLoadingDiv		= $('#loading');
	faqKeyword 				= $('#faq_keyword');
	faqCategory 			= $('#faq_category');
	faqButton 				= $('#faq_button');
	getAjaxRequest 			= "";
	pageUrl 				= ajaxLocation+"faqs";
	configurePagination();
	
	faqButton.click(function(){
		getAjaxRequest = faqsPagination(1,getAjaxRequest,false);
	});
	
	$('.backtoTop a').click(function(e){
		e.preventDefault();
		$('html,body').animate({scrollTop: $('body').offset().top}, 500);
	});
	
}
function faqsPagination(currentPage,ajaxRequest,popState){
	searchStartStopLoading(true);
	if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
	var faqsUrl = ajaxLocation+"faqs";
	var url = faqsUrl;
	

	if(faqKeyword.val() !== undefined && faqKeyword.val() !== ""){
		faqsUrl += "/keyword/"+faqKeyword.val();
		url 		+= "/keyword/"+faqKeyword.val();
	}
	if(faqCategory.val() !== undefined && faqCategory.val() !== ""){
		faqsUrl += "/category/"+faqCategory.val();
		url 		+= "/category/"+faqCategory.val();
	}
	faqsUrl += "/page/"+currentPage	
	url += "/page/"+currentPage;
	
	ajaxRequest = $.ajax({ type:'GET', url: faqsUrl, dataType: "html" });
	ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);		
			configurePagination();			
			searchStartStopLoading(false);
			pageLoaded = true;	
			if(!popState){
				history.pushState(url, "FAQs", url);			
			}
			popStateCheck = 1;		
			
		},
		function(data){
		}
	); 
	return ajaxRequest;
}
function popStateFaqsPage(stateUrl){	
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("faqs");
		var pageNumber  = 0;
		var searchField = "";
		if(urlSegments[1].indexOf("/page/") === 2){
			searchField = urlSegments[1].split("/page/");			
			searchField = searchField[0];
			searchField = searchField.split("/");			
			if(faqKeyword.val() === undefined){
				faqKeyword = $('#faq_keyword');
			}
			faqKeyword.val(searchField[1]);
		}
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}				
		if(popStateCheck === 1){
			getAjaxRequest = faqsPagination(pageNumber,getAjaxRequest,true);				
		}
		popStateCheck = 1;	
	}
	else{		
		getAjaxRequest = faqsPagination(1,getAjaxRequest,true);
	}
}

/*************************************************************************/

/********Variables && Methods Exclusive To The Agents Page ***************/
var agentNameSearch = null,
	agentButton = null;

function configureAgents(){		
	$('body').removeClass('main_index');	
	getCurrentPage = $('#current_page').val();
	mainPropertyContainer = $('#main_agent_container');
	searchLoadingDiv = $('#loading');
	agentNameSearch = $('#agent_name_text');
	agentButton = $('#agent_submit');
	getAjaxRequest = "";
	pageUrl = ajaxLocation+"agents";
	configurePagination();

	agentButton.click(function(){
		getAjaxRequest = agentsPagination(1,getAjaxRequest,false);
	});
	
}

function agentsPagination(currentPage,ajaxRequest,popState){
	searchStartStopLoading(true);
	if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
	var agentsUrl = ajaxLocation+"ajax-agent";
	var url = pageUrl;
	if(agentNameSearch.val() !== ""){
		agentsUrl += "/"+agentNameSearch.val();
		url += "/"+agentNameSearch.val();
	}
	agentsUrl += "/page/"+currentPage	
	url += "/page/"+currentPage;
	
	ajaxRequest = 	$.ajax({
						type:'GET',
						url: agentsUrl,
						dataType: "html"
					});
	ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);		
			configurePagination();			
			searchStartStopLoading(false);
			pageLoaded = true;	
			if(!popState){
				history.pushState(url, "Agents", url);			
			}
			popStateCheck = 1;			
			$(document).foundation();
			
		},
		function(data){
		}
	); 
	return ajaxRequest;
}

function popStateAgentPage(stateUrl){	
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("agents");
		var pageNumber  = 0;
		var searchField = "";
		if(urlSegments[1].indexOf("/page/") === 2){
			searchField = urlSegments[1].split("/page/");			
			searchField = searchField[0];
			searchField = searchField.split("/");			
			if(agentNameSearch.val() === undefined){
				agentNameSearch = $('#agent_name_text');
			}
			agentNameSearch.val(searchField[1]);
		}
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}				
		if(popStateCheck === 1){
			getAjaxRequest = agentsPagination(pageNumber,getAjaxRequest,true);				
		}
		popStateCheck = 1;	
	}
	else{		
		getAjaxRequest = agentsPagination(1,getAjaxRequest,true);
	}
}
/*************************************************************************/

/********Variables && Methods Exclusive To The Search Page ***************/
var searchFieldHidden = null,
	priceFromHidden = null,
	priceToHidden = null,
	idSearchHidden = null,
	rentSaleHidden = null,
	currencyHidden = null,
	bedHidden = null,
	bathHidden = null,
	propertyTypeHidden = null,
	categoryHidden = null,
	similarHidden = null,
	mainPropertyContainer = null,	
	searchLimit = null,
	searchLimitSelect = null,
	searchRangeSelect = null,	
	searchRange = null,
	searchLoadingDiv = null,	
	searchListingActivity = null,
	searchListingAmmenities = null,
	searchAgentSlug = null,
	searchListingTypes = null;

/*************************************************************************/
/*Configures the property search page of the application. 				 */
/*************************************************************************/
function configureSearch(){		
	pageUrl 			= ajaxLocation+"search";
	searchLimitSelect 	= $('#limit');
	searchRangeSelect 	= $('#price_order');	
	getCurrentPage 		= $('#current_page').val();
	searchLimit 		= 15;
	searchRange 		= "H";
	getAjaxRequest 		= "";
	searchLoadingDiv 	= $('#loading');	
	searchFieldHidden 	= $('#search_field_hidden');
	priceFromHidden 	= $('#price_from_hidden');
	priceToHidden 		= $('#price_to_hidden');
	priceRangeSelect 	= $("select[name='price']");
	
	propertyArea		= $('#area_field_hidden');
	parishHidden 		= $('#parish_field_hidden');
	townHidden 			= $('#town_field_hidden');
	
	rentSaleHidden 		= $('#rentsale_hidden');
	currencyHidden 		= $('#currency_hidden');	
	idSearchHidden 		= $('#id_field_hidden');
	bedHidden 			= $('#bed_hidden');
	bathHidden 			= $('#bath_hidden');
	propertyTypeHidden 	= $('#property_type_hidden');
	categoryHidden 		= $('#category_hidden');
	similarHidden 		= $('#similar_hidden');
	hotDealHidden 		= $('#hot_deal_hidden');
	maximumPriceHidden 	= $('#maximum_price_hidden');
	minimumPriceHidden 			= $('#minimum_price_hidden');
	averagePriceHidden 			= $('#average_price_hidden');
	similarPirceHidden 			= $('#similar_price_hidden');
	similarPirceHidden2 			= $('#similar_price_hidden2');




	mainPropertyContainer 	= $('#main_property_container');
	searchListingActivity 	= $('input[name="price_reduced[]"]');
	searchListingAmmenities = $('input[name="ammenities[]"]');
	searchListingTypes 			= $('input[name="property_type[]"]');
	searchAgentSlug 				= $('#agent_slug');
	searchAgentId 				= $('#agent_id');
	
	if(searchAgentId.length > 0 && searchAgentId.val() > 0){
		searchLimit = 8;
	}
	searchNextLink 					= $('#next_link');
	searchPreviousLink 			= $('#previous_link');
	searchGotoPage 					= $('#gotopage');
	
	
	configurePagination();

	bedFilterField 		= $('#bed');
	bathFilterField 	= $('#bath');

	bedFilterField.change(function(){
		bedHidden.val(bedFilterField.val());
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});
	bathFilterField.change(function(){
		bathHidden.val(bathFilterField.val());
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});

	
	if(priceFromHidden.val() != "" && priceToHidden.val() != ""){
		var priceRangeSelectString = parseFloat(priceFromHidden.val())+'-'+parseFloat(priceToHidden.val());
		priceRangeSelectString = priceRangeSelectString.replace(",", "");
		
	}else{
		priceRangeSelectString = "";
	}
	
	priceRangeSelect.val(priceRangeSelectString);
	
//	if(priceFromHidden.val() >= 0 && parseInt(priceToHidden.val()) <= 5000){
//		priceRangeSelect.val('0-5');	
//	}else if(priceFromHidden.val() >= 5000 && parseInt(priceToHidden.val()) <= 10000){
//		priceRangeSelect.val('5-10');
//	}else if(priceFromHidden.val() >= 10000 && parseInt(priceToHidden.val()) <= 50000){
//		priceRangeSelect.val('10-50');
//	}else if(priceFromHidden.val() >= 50000 && parseInt(priceToHidden.val()) <= 100000){
//		priceRangeSelect.val('50-100');
//	}else if(priceFromHidden.val() >= 100000 && parseInt(priceToHidden.val()) <= 500000){
//		priceRangeSelect.val('100-500');
//	}else if(priceFromHidden.val() >= 500000 && parseInt(priceToHidden.val()) <= 999000){
//		priceRangeSelect.val('500-999');
//	}else if(priceFromHidden.val() >= 1000000 && parseInt(priceToHidden.val()) <= 3000000){
//		priceRangeSelect.val('1-3M');
//	}else if(priceFromHidden.val() >= 3000000 && parseInt(priceToHidden.val()) <= 6000000){
//		priceRangeSelect.val('3-6M');
//	}else if(priceFromHidden.val() >= 6000000 && parseInt(priceToHidden.val()) <= 9000000){
//		priceRangeSelect.val('6-9M');
//	}else if(priceFromHidden.val() >= 9000000 && parseInt(priceToHidden.val()) <= 12000000){
//		priceRangeSelect.val('9-12M');
//	}else if(priceFromHidden.val() >= 12000000 && parseInt(priceToHidden.val()) <= 15000000){
//		priceRangeSelect.val('12-15M');
//	}else if(priceFromHidden.val() >= 15000000){
//		priceRangeSelect.val('15_plus');
//	}
	
	
	
	searchLimitSelect.change(function(){
		searchLimit = parseInt(searchLimitSelect.val());
		searchRange = searchRangeSelect.val();
		getCurrentPage = 1;		
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});

	searchRangeSelect.change(function(){		
		if( parseInt(searchLimit) !== parseInt(searchLimitSelect.val())){
			searchLimit = parseInt(searchLimitSelect.val());
			getCurrentPage = 1;
		}				
		searchRange = searchRangeSelect.val();				
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});

	searchListingActivity.click(function(){
		$('html,body').animate({scrollTop: $('body').offset().top}, 500);
		searchLimit = parseInt(searchLimitSelect.val());
		searchRange = searchRangeSelect.val();
		getCurrentPage = 1;
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});

	searchListingAmmenities.click(function(){
		$('html,body').animate({scrollTop: $('body').offset().top}, 500);
		searchLimit = parseInt(searchLimitSelect.val());
		searchRange = searchRangeSelect.val();
		getCurrentPage = 1;
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});

	searchListingTypes.click(function(){		
		$('html,body').animate({scrollTop: $('body').offset().top}, 500);
		searchLimit = parseInt(searchLimitSelect.val());
		searchRange = searchRangeSelect.val();
		getCurrentPage = 1;
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
		
	});
	
	searchNextLink.click(function(e){
		e.preventDefault();
		searchLimit = parseInt(searchLimitSelect.val());
		searchRange = searchRangeSelect.val();
		if(getCurrentPage !== parseInt($('input[name="number_pages"]').val())){
			getCurrentPage++;
		} 
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
		
		
	});
	searchPreviousLink.click(function(e){
		e.preventDefault();
		searchRange = searchRangeSelect.val();
		searchLimit = parseInt(searchLimitSelect.val());
		if(getCurrentPage !== 1 && getCurrentPage !== 0){
			getCurrentPage--;
		} 
		
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
	});
	
	$(document).on('.pagination ul li.next a ','click',function(e){
		
		searchLimit = parseInt(searchLimitSelect.val());
		searchRange = searchRangeSelect.val();
		if(getCurrentPage !== parseInt($('input[name="number_pages"]').val())){
			getCurrentPage++;
		} 
		
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
		
	});

	$(document).on('.pagination li.previous a','click',function(e){
		searchRange = searchRangeSelect.val();
		searchLimit = parseInt(searchLimitSelect.val());
		if(getCurrentPage !== 1 && getCurrentPage !== 0){
			getCurrentPage--;
		} 
		
		getAjaxRequest = propertySearchRefine(searchLimit,searchRange,getCurrentPage,getAjaxRequest,false);
		
	});	

	$(document).on('click','a.save_property_favourite',function(e){
		e.preventDefault();	
		var self = $(this);
		$("img.save_image_favourite").each(function(){
			if($(this).data('property-id') === self.data("property-id"))
				saveImg = $(this);
		});
		$("span.saved_title_title").each(function(){
			if($(this).data('property-id') === self.data("property-id"))
				savedTitle = $(this);
		});		
		var image_src = saveImg.attr('src');
		var favouriteUrl = ajaxLocation+"ajax-favourite/property_id/"+$(this).data("property-id")+"/mls/"+$(this).data("mls")+"/saved/"+$(this).data("saved");		
		savePropertyAjaxRequest = $.ajax({
									type:'POST',
									url: favouriteUrl,
									dataType: "json",									
								}
							);
		savePropertyAjaxRequest.then(
			function(data){
				
				if(data.status === "200"){
					if( self.data("saved") === 0 ){
						self.data("saved",1);
						savedTitle.html("Remove Favourite");
						$('#save_property img');
						image_src = image_src.replace("save_property.png", "save_property_red.png");
						saveImg.attr('src', image_src);
						$('#modal_header_2').html("Saved");
						$('#modal_paragraph_2').html("Favourite Was Saved Successfully");
						$('#modal_button_2').val("Ok");
						$('#details_modal_2').removeClass('failed');
					}
					else{
						self.data("saved",0);
						savedTitle.html("Add to Favourite");
						image_src = image_src.replace("_red", "");
						saveImg.attr('src', image_src);
						$('#modal_header_2').html("Removed");
						$('#modal_paragraph_2').html("Favourite Was Removed Successfully");
						$('#modal_button_2').val("Ok");
						$('#details_modal_2').removeClass('failed');
					}
				}
				else{
					if( self.data("saved") === 0 ){
						$('#modal_header_2').html("Error");
						$('#modal_paragraph_2').html("Favourite Was Not Saved, Please Try Again");
						$('#modal_button_2').val("Ok");
						$('#details_modal_2').addClass('failed');
					}
					else{
						$('#modal_header_2').html("Error");
						$('#modal_paragraph_2').html("Favourite Was Not Removed, Please Try Again");
						$('#modal_button_2').val("Ok");
						$('#details_modal_2').addClass('failed');
					}
				}	
				$('#details_modal_2').modal('show');		
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);				
			},
			function(data){				
				//$('#details_modal').addClass('failed');
			}
		);
	});

	$(document).on('click','#modal_button_2',function(e){		
		$('#details_modal_2').modal('hide');	
	});

}

/**************************************************************************/
/*This method manages the onpopstate for the property search page in      */
/*regards to page navigation. It will dissassemble the state (url) 		  */
/*received from the onpopstate method to appropriately reconfigure search */ 
/*parameters.															  */
/**************************************************************************/
function popStateSearchPage(stateUrl) {		
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("search");	
		var pageNumber  = 0;
		var searchField  = "";
		var fromField = "";
		var toField = "";
		var rentSaleField = "";
		var currencyField = "";
		var propertyTypeField = "";
		var propertyAreaField = "";
		var bedField = "";
		var bathField = "";
		var categoryField = "";
		var similarField = "";
		var agentField = "";
		var hotDealsField = "";
		var maximumField = "";
		var minimumField = "";
		var averageField = "";
		var similarRangeField = "";
		var similarRange2Field = "";
		var idField = "";
		var limitField = 20;
		var rangeField = 20;

		if(urlSegments[1].indexOf("/agent/") !== -1){
			agentField = urlSegments[1].split("/agent/");
			agentField = agentField[1];
			agentField = agentField.split("/");
			agentField = agentField[0].split("-");
			searchAgentSlug.val(agentField[0]);			
			searchAgentId.val(agentField[1]);	
		}	
		if(urlSegments[1].indexOf("/hotdeals/") !== -1){
			hotDealsField = urlSegments[1].split("/hotdeals/");
			hotDealsField = hotDealsField[1];
			hotDealsField = hotDealsField.split("/");
			hotDealHidden.val(hotDealsField[0]);			
		}	
		if(urlSegments[1].indexOf("/maximum_price/") !== -1){
			maximumField = urlSegments[1].split("/maximum_price/");
			maximumField = maximumField[1];
			maximumField = maximumField.split("/");
			maximumPriceHidden.val(maximumField[0]);			
		}	
		if(urlSegments[1].indexOf("/average_price/") !== -1){
			averageField = urlSegments[1].split("/average_price/");
			averageField = averageField[1];
			averageField = averageField.split("/");
			averagePriceHidden.val(averageField[0]);			
		}	
		if(urlSegments[1].indexOf("/minimum_price/") !== -1){
			minimumField = urlSegments[1].split("/minimum_price/");
			minimumField = minimumField[1];
			minimumField = minimumField.split("/");
			minimumPriceHidden.val(minimumField[0]);			
		}	
		if(urlSegments[1].indexOf("/price_range/") !== -1){
			similarRangeField = urlSegments[1].split("/price_range/");
			similarRangeField = similarRangeField[1];
			similarRangeField = similarRangeField.split("/");
			similarPirceHidden.val(similarRangeField[0]);			
		}	
		if(urlSegments[1].indexOf("/similar_range/") !== -1){
			similarRange2Field = urlSegments[1].split("/similar_range/");
			similarRange2Field = similarRange2Field[1];
			similarRange2Field = similarRange2Field.split("/");
			similarPirceHidden2.val(similarRange2Field[0]);			
		}		
		
		if(urlSegments[1].indexOf("/parish/") !== -1){
			parishField = urlSegments[1].split("/parish/");
			parishField = parishField[1];
			parishField = parishField.split("/");		
			parishHidden.val(parishField[0]);	
		}
		if(urlSegments[1].indexOf("/town/") !== -1){
			parishField = urlSegments[1].split("/town/");
			parishField = parishField[1];
			parishField = parishField.split("/");		
			townHidden.val(parishField[0]);	
		}
		
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}	
		if(urlSegments[1].indexOf("/keywords/") !== -1){
			searchField = urlSegments[1].split("/keywords/");
			searchField = searchField[1];
			searchField = searchField.split("/");		
			searchFieldHidden.val(searchField[0]);	
		}
		if(urlSegments[1].indexOf("/id_search/") !== -1){
			idField = urlSegments[1].split("/id_search/");
			idField = idField[1];
			idField = idField.split("/");		
			idSearchHidden.val(idField[0]);	
		}
		if(urlSegments[1].indexOf("/from/") !== -1){
			fromField = urlSegments[1].split("/from/");
			fromField = fromField[1];
			fromField = fromField.split("/");	
			priceFromHidden.val(fromField[0]);		
		}
		if(urlSegments[1].indexOf("/to/") !== -1){
			toField = urlSegments[1].split("/to/");
			toField = toField[1];
			toField = toField.split("/");
			priceToHidden.val(toField[0]);
		}
		if(urlSegments[1].indexOf("/rentsale/") !== -1){
			rentSaleField = urlSegments[1].split("/rentsale/");
			rentSaleField = rentSaleField[1];
			rentSaleField = rentSaleField.split("/");
			rentSaleHidden.val(rentSaleField[0]);		
		}
		
		if(urlSegments[1].indexOf("/area/") !== -1){
			propertyAreaField = urlSegments[1].split("/area/");
			propertyAreaField = propertyAreaField[1];
			propertyAreaField = propertyAreaField.split("/");
			propertyArea.val(propertyAreaField[0]);				
		}
		
		
		if(urlSegments[1].indexOf("/limit/") !== -1){
			limitField = urlSegments[1].split("/limit/");
			limitField = limitField[1];
			limitField = limitField.split("/");
			limitField = limitField[0];		
		}
		if(urlSegments[1].indexOf("/range/") !== -1){
			rangeField = urlSegments[1].split("/range/");
			rangeField = rangeField[1];
			rangeField = rangeField.split("/");
			rangeField = rangeField[0];		
		}
		if(urlSegments[1].indexOf("/currency/") !== -1){
			currencyField = urlSegments[1].split("/currency/");
			currencyField = currencyField[1];
			currencyField = currencyField.split("/");
			currencyHidden.val(currencyField[0]);				
		}
		if(urlSegments[1].indexOf("/bed/") !== -1){
			bedField = urlSegments[1].split("/bed/");
			bedField = bedField[1];
			bedField = bedField.split("/");
			bedHidden.val(bedField[0]);				
		}
		if(urlSegments[1].indexOf("/bath/") !== -1){
			bathField = urlSegments[1].split("/bath/");
			bathField = bathField[1];
			bathField = bathField.split("/");
			bathHidden.val(bathField[0]);				
		}
		if(urlSegments[1].indexOf("/propertytype/") !== -1){
			propertyTypeField = urlSegments[1].split("/propertytype/");
			propertyTypeField = propertyTypeField[1];
			propertyTypeField = propertyTypeField.split("/");
			propertyTypeHidden.val(propertyTypeField[0]);				
		}
		if(urlSegments[1].indexOf("/category/") !== -1){
			categoryField = urlSegments[1].split("/category/");
			categoryField = categoryField[1];
			categoryField = categoryField.split("/");
			categoryHidden.val(categoryField[0]);				
		}
		if(urlSegments[1].indexOf("/similar/") !== -1){
			similarField = urlSegments[1].split("/similar/");
			similarField = similarField[1];
			similarField = similarField.split("/");
			similarHidden.val(similarField[0]);				
		}

		if(popStateCheck === 1){					
			getAjaxRequest = propertySearchRefine(limitField,rangeField,pageNumber,getAjaxRequest,true);
		}
		popStateCheck = 1;
	}
	else{		
		getAjaxRequest = propertySearchRefine(searchLimitSelect.val(),searchRangeSelect.val(),1,getAjaxRequest,true);
	}	
}

/**************************************************************************/
/*This method manages the ajax search refinement queries made by the user.*/
/**************************************************************************/
function propertySearchRefine(limitParam,rangeParam,currentPage,ajaxRequest,popState){

	$('#currentPageText').text(currentPage);
	$('#gotopage').val(currentPage);
	
	
	searchStartStopLoading(true);
	if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
    
	var url = "",
		searchUrl = "",
		listingActivity = "",
		listingAmmenities = "",
		listingTypes = "";

	if(hotDealHidden.val() !== undefined && hotDealHidden.val().trim() !== "" ){
		url = searchUrl += "/hotdeals";
	}

	




//similarPirceHidden2

	if(categoryHidden.val()!== undefined && categoryHidden.val().trim() !== ""){
		url = searchUrl += "/" + categoryHidden.val();
	}

		
	if(rentSaleHidden.val() !== undefined && rentSaleHidden.val().trim() !== "" && rentSaleHidden.val().trim() !== "both"){
		url = searchUrl += "/" + rentSaleHidden.val();
	}

	if(searchAgentSlug.val()!== undefined && searchAgentId.val() !== undefined){
		url = searchUrl += "/agent/" + searchAgentSlug.val() + "-" + searchAgentId.val();
	}
    
    if(searchFieldHidden.val() !== undefined && searchFieldHidden.val().trim() !== ""){
		url = searchUrl += "/keywords/" + searchFieldHidden.val().replace(" ", "+");			
	}

	if(parishHidden.val() !== undefined && parishHidden.val().trim() !== ""){
		url = searchUrl += "/parish/" + parishHidden.val().replace(" ", "+");			
	}
	if(townHidden.val() !== undefined && townHidden.val().trim() !== ""){
		url = searchUrl += "/town/" + townHidden.val().replace(" ", "+");			
	}
	
	if(maximumPriceHidden.val() !== undefined && maximumPriceHidden.val().trim() !== ""){
		url = searchUrl += "/maximum_price/" + maximumPriceHidden.val();			
	}
	if(minimumPriceHidden.val() !== undefined && minimumPriceHidden.val().trim() !== ""){
		url = searchUrl += "/minimum_price/" + minimumPriceHidden.val();			
	}
	if(averagePriceHidden.val() !== undefined && averagePriceHidden.val().trim() !== ""){
		url = searchUrl += "/average_price/" + averagePriceHidden.val();			
	}
	if(averagePriceHidden.val() !== undefined && averagePriceHidden.val().trim() !== ""){
		url = searchUrl += "/average_price/" + averagePriceHidden.val();			
	}
	if(similarPirceHidden2.val() !== undefined && similarPirceHidden2.val().trim() !== ""){
		url = searchUrl += "/similar_range/" + similarPirceHidden2.val();			
	}
	if(similarPirceHidden.val() !== undefined && similarPirceHidden.val().trim() !== ""){
		url = searchUrl += "/price_range/" + similarPirceHidden.val();			
	}
    
	if(bedHidden.val() !== undefined && bedHidden.val().trim() !== ""){
		url = searchUrl += "/bed/" + bedHidden.val();			
	}
    
	if(bathHidden.val() !== undefined && bathHidden.val().trim() !== ""){
		url = searchUrl += "/bath/" + bathHidden.val();			
	}
    
	if(priceFromHidden.val() !== undefined && priceFromHidden.val().trim() !== ""){
		url = searchUrl += "/price_from/" + priceFromHidden.val();		
	}
    
	if(priceToHidden.val() !== undefined && priceToHidden.val().trim() !== ""){
		url = searchUrl += "/price_to/" + priceToHidden.val();
	}
    
	if(currencyHidden.val() !== undefined && currencyHidden.val().trim() !== ""){
		url = searchUrl += "/currency/" + currencyHidden.val();			
	}	
	
	if(propertyTypeHidden.val() !== undefined && propertyTypeHidden.val().trim() !== ""){
		url = searchUrl += "/property_type/" + propertyTypeHidden.val();			
	}

	if(propertyArea.val() !== undefined && propertyArea.val().trim() !== ""){
		url = searchUrl += "/area/" + propertyArea.val();			
	}
	
	if(parseInt(currentPage) > 0){
		url = searchUrl += "/page/" + currentPage;
	}
    
	
    
    if(similarHidden.val() !== undefined && similarHidden.val().trim() !== ""){
		url = searchUrl += "/similar/" + similarHidden.val();		
	}

	if(bedFilterField.val() !== undefined && bedFilterField.val().trim() !== ""){
		url = searchUrl += "/bed/" + bedFilterField.val();		
	}
	if(bathFilterField.val() !== undefined && bathFilterField.val().trim() !== ""){
		url = searchUrl += "/bath/" + bathFilterField.val();		
	}



    
    url = searchUrl += "/limit/" + limitParam + "/range/" + rangeParam;

	searchListingActivity.each(function(){
		var thisElement = $(this);
		if(thisElement.is(':checked')){
			listingActivity += thisElement.val() +"-";
		}		
	});
    
    if(listingActivity !== ""){
		listingActivity = listingActivity.substring(0, listingActivity.length - 1);
		searchUrl += "/status/" + listingActivity;
	}

	searchListingAmmenities.each(function(){
		var thisElement = $(this);
		if(thisElement.is(':checked')){
			listingAmmenities += thisElement.val() + "-";
		}
	});
	
	searchListingTypes.each(function(){
		var thisElement = $(this);
		if(thisElement.is(':checked')){
			listingTypes += thisElement.val() + "-";
		}
	});

	if(listingAmmenities !== ""){
		listingAmmenities = listingAmmenities.substring(0, listingAmmenities.length - 1);
		searchUrl += "/ammenities/" + listingAmmenities;
		url += "/amenities/" + listingAmmenities;
	}

	if(listingTypes !== ""){
		listingTypes = listingTypes.substring(0, listingTypes.length - 1);
		searchUrl += "/property_type/" + listingTypes;
	}	
    
    var fullSearchUrl = ajaxLocation + "search" + searchUrl,
    	fullPageUrl = pageUrl + url;
    
	ajaxRequest = 	$.ajax({
						type:'GET',
						url: fullSearchUrl,
						dataType: "html"
					});
	
    ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);			
			$('#currentPageTextTop').html($('#currentPageText').html());				
			if($('#totalPageNumberSpan').html() === undefined){
				$('#currentPageTextTop').hide();
				$('#previous_link').hide();
				$('#next_link').hide();
				$('.controls_buttons').hide();
			}		
			else{
				$('#totalPageNumberSpanTop').html($('#totalPageNumberSpan').html());
				$('#currentPageTextTop').show();
				$('#previous_link').show();
				$('#next_link').show();
				$('.controls_buttons').show();
			}						
			configurePagination();
			searchStartStopLoading(false);
			popStateCheck = 1;
			pageLoaded = true;	
			if(!popState){
				history.pushState(fullPageUrl, "Property Search", fullPageUrl);			
			}
			var totalResults = $("#total_results_hidden").val();
            $("#total_results").text(totalResults);  
            (function(d, s, id) {
            	var js, fjs = d.getElementsByTagName(s)[0];
            	if (d.getElementById(id)) return;
            	js = d.createElement(s); js.id = id;
            	js.src = "//connect.facebook.net/en_US/all.js#xfbml=1&appId=245469182219619";
            	fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));      

		},
		function(data){
		}
	);    
	return ajaxRequest;	
}
/*************************************************************************/

/******Variables && Methods Exclusive To The Developments Page ***********/

function configureDevelopments(){
	getCurrentPage = $('#current_page').val();
	mainPropertyContainer = $('#main_property_container');
	searchLoadingDiv = $('#loading');
	getAjaxRequest = "";
	pageUrl = ajaxLocation+"developments";
	configurePagination();
}

function developmentPagination(currentPage,ajaxRequest,popState){
	searchStartStopLoading(true);	
	if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
	var developmentUrl = ajaxLocation+"ajax-development/page/"+currentPage;
	var url = pageUrl+"/page/"+currentPage;
	ajaxRequest = 	$.ajax({
						type:'GET',
						url: developmentUrl,
						dataType: "html"
					});
	ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);
			configurePagination();
			searchStartStopLoading(false);
			pageLoaded = true;	
			if(!popState){
				history.pushState(url, "Development Properties", url);			
			}
			popStateCheck = 1;			
		},
		function(data){
		}
	); 
	return ajaxRequest;
}

function popStateDevelopmentPage(stateUrl){	
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("developments");
		var pageNumber  = 0;
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}		
		if(popStateCheck === 1){
			getAjaxRequest = developmentPagination(pageNumber,getAjaxRequest,true);				
		}
		popStateCheck = 1;	
	}
	else{		
		getAjaxRequest = developmentPagination(1,getAjaxRequest,true);
	}
}
/*************************************************************************/

var newsStartDate = null,
	newsEndDate = null,
	newsSearchButton = null;


function configureNews(){
	$('.general_date_picker').datepicker({
	     showOn: "button",
	     buttonImage: ajaxLocation+"images/calendar.png",
	     buttonImageOnly: true,
	     dateFormat: 'yy-mm-dd',
	     buttonText: "Select date"
	});
	   
	getCurrentPage = $('#current_page').val();
	mainPropertyContainer = $('#main_news_container');
	searchLoadingDiv = $('#loading');
	newsStartDate = $('#from_date');
	newsEndDate = $('#to_date');
	newsSearchButton = $('#search_news');
	getAjaxRequest = "";
	pageUrl = ajaxLocation+"news";
	configurePagination();

	newsSearchButton.click(function(){
		getAjaxRequest = newsPagination(1,getAjaxRequest,false);
	});
}

function newsPagination(currentPage,ajaxRequest,popState){
	searchStartStopLoading(true);
	if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
	var newsItemUrl = ajaxLocation+"ajax-news/page/"+currentPage;
	var url = pageUrl+"/page/"+currentPage;

	if(newsStartDate.val() !== ""){
		newsItemUrl += "/start_date/"+newsStartDate.val();
		url += "/start_date/"+newsStartDate.val();
	}
	if(newsEndDate.val() !== ""){
		newsItemUrl += "/end_date/"+newsEndDate.val();
		url += "/end_date/"+newsEndDate.val();
	}	
	ajaxRequest = 	$.ajax({
						type:'GET',
						url: newsItemUrl,
						dataType: "html"
					});
	ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);
			configurePagination();
			searchStartStopLoading(false);
			pageLoaded = true;	
			if(!popState){
				history.pushState(url, "News Items", url);			
			}
			popStateCheck = 1;			
		},
		function(data){
		}
	); 
	return ajaxRequest;
}

function popStateNewsPage(stateUrl){	
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("news");
		var pageNumber  = 0;
		var startField = "";
		var endField = "";		
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}	
		if(urlSegments[1].indexOf("/start_date/") !== -1){
			startField = urlSegments[1].split("/start_date/");
			startField = startField[1];
			startField = startField.split("/");	
			newsStartDate.val(startField[0]);		
		}
		if(urlSegments[1].indexOf("/end_date/") !== -1){
			endField = urlSegments[1].split("/end_date/");
			endField = endField[1];
			endField = endField.split("/");
			newsEndDate.val(endField[0]);
		}	
		if(popStateCheck === 1){
			getAjaxRequest = newsPagination(pageNumber,getAjaxRequest,true);				
		}
		popStateCheck = 1;	
	}
	else{		
		getAjaxRequest = newsPagination(1,getAjaxRequest,true);
	}
}

/*************************************************************************/


var gallerySearch = null;


function configureGallery(){
	$('.gallery_item').fancybox({
		openEffect  : 'elastic',
		closeEffect : 'elastic',

		prevEffect : 'elastic',
		nextEffect : 'elastic',

		closeBtn  : false,

		helpers : {
			title : {
				type : 'inside'
			},
			buttons	: {}
		},

		afterLoad : function() {
			this.title = 'Image ' + (this.index + 1) + ' of ' + this.group.length + (this.title ? ' - ' + this.title : '');
		}
	});

	getCurrentPage 			= $('#current_page').val();
	mainPropertyContainer 	= $('#main_gallery_container');
	searchLoadingDiv 		= $('#loading');
	gallerySearch 			= $('#search');
	gallerySearchButton 	= $('#search_gallery');
	getAjaxRequest 			= "";
	pageUrl 				= ajaxLocation+"gallery";
	configurePagination();

	gallerySearchButton.click(function(){
		getAjaxRequest = galleryPagination(1,getAjaxRequest,false);
	});
}

function galleryPagination(currentPage,ajaxRequest,popState){
	searchStartStopLoading(true);
	if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
	var galleryItemUrl = ajaxLocation+"ajax-gallery/page/"+currentPage;
	var url = pageUrl+"/page/"+currentPage;

	if(gallerySearch.val() !== ""){
		galleryItemUrl += "/search/"+gallerySearch.val();
		url += "/search/"+gallerySearch.val();
	}	
	ajaxRequest = 	$.ajax({
						type:'GET',
						url: galleryItemUrl,
						dataType: "html"
					});
	ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);
			configurePagination();
			searchStartStopLoading(false);
			pageLoaded = true;	
			if(!popState){
				history.pushState(url, "Gallery", url);			
			}
			popStateCheck = 1;			
		},
		function(data){
		}
	); 
	return ajaxRequest;
}

function popStateGalleryPage(stateUrl){	
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("gallery");
		var pageNumber  = 0;
		var searchField = "";	
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}	
		if(urlSegments[1].indexOf("/search/") !== -1){
			searchField = urlSegments[1].split("/search/");
			searchField = searchField[1];
			searchField = searchField.split("/");	
			newsStartDate.val(searchField[0]);		
		}	
		if(popStateCheck === 1){
			getAjaxRequest = galleryPagination(pageNumber,getAjaxRequest,true);				
		}
		popStateCheck = 1;	
	}
	else{		
		getAjaxRequest = galleryPagination(1,getAjaxRequest,true);
	}
}

/*************************************************************************/
function configureTips(){
	getCurrentPage 				= $('#current_page').val();
	mainPropertyContainer = $('#main_tips_container');
	searchLoadingDiv 			= $('#loading');
	tipKeyword 						= $('#search_by_name');
	tipCategory 					= $('#tip_category');
	tipButton 						= $('#search_tips');
	getAjaxRequest 				= "";
	pageUrl 							= ajaxLocation+"tips";
	configurePagination();
	
	tipButton.click(function(){
		getAjaxRequest = tipsPagination(1,getAjaxRequest,false);
	});
	
	$('.backtoTop a').click(function(e){
		e.preventDefault();
		$('html, body').animate({ scrollTop: $("body").offset().top }, 2000);
	});	
}
function tipsPagination(currentPage,ajaxRequest,popState){	
	searchStartStopLoading(true);
	if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
	
	var tipsItemUrl = ajaxLocation+"tips";
	var url 				= tipsItemUrl;
	
	if(tipKeyword.val() !== ""){
		tipsItemUrl += "/keyword/"+tipKeyword.val();
		url 		+= "/keyword/"+tipKeyword.val();
	}
	if(tipCategory.val() !== ""){
		tipsItemUrl += "/category/"+tipCategory.val();
		url 		+= "/category/"+tipCategory.val();
	}
	tipsItemUrl += "/page/"+currentPage	
	url += "/page/"+currentPage;
	
	
	ajaxRequest = $.ajax({ type:'GET', url: tipsItemUrl, dataType: "html" });
	ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);		
			configurePagination();			
			searchStartStopLoading(false);
			pageLoaded = true;	
			if(!popState){
				history.pushState(url, "Real Estate Tips", url);			
			}
			popStateCheck = 1;		
			
		},
		function(data){
		}
	); 
	return ajaxRequest;
}
function popStateTipsPage(stateUrl){	
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("tips");
		var pageNumber  = 0;
		var searchField = "";
		if(urlSegments[1].indexOf("/page/") === 2){
			searchField = urlSegments[1].split("/page/");			
			searchField = searchField[0];
			searchField = searchField.split("/");			
			if(tipKeyword.val() === undefined){
				tipKeyword = $('#search_by_name');
			}
			tipKeyword.val(searchField[1]);
		}
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}				
		if(popStateCheck === 1){
			getAjaxRequest = tipsPagination(pageNumber,getAjaxRequest,true);				
		}
		popStateCheck = 1;	
	}
	else{		
		getAjaxRequest = tipsPagination(1,getAjaxRequest,true);
	}
}

/*************************************************************************/

/******Variables && Methods Exclusive To List My Property Page ***********/
var listForm = null,
	listFirstName = null,
	listLastName = null,
	listEmail = null,
	listComment = null,
	listCurrency = null,
	listPrice = null,
	listSubmit = null,
	listPropertyType = null;
function configureListMyProperty(){
	spamFilter = $('#email_address_sent');
	listForm = $('#list-property-form');
	listSubmit = $('#submit_list_request');
	listFirstName = $('#ListPropertyForm_firstName');
	listLastName = $('#ListPropertyForm_lastName');
	listEmail = $('#ListPropertyForm_email');
	listComment = $('#ListPropertyForm_comment');
	listCurrency = $('#ListPropertyForm_currency');
	listPrice = $('#ListPropertyForm_price');
	listPropertyType = $('input[name="ListPropertyForm[propertyType][]"]');
	searchLoadingDiv = $('#loading');
	mainContainer = $('#list_div');
	validator = listForm.validate();
	listForm.removeAttr("novalidate");	

	listFirstName.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your First Name"
         }
	});
	listLastName.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your Last Name"
         }
	});
	listEmail.rules("add",{
		 email:"required",
		 required:true,
		 messages: {
			 email: "Please Enter A Valid Email Address",
			 required:"Please Enter Your Email Address"
        }
	});
	listComment.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your Message"
         }
	});
	listCurrency.rules("add",{
		required:true,
		 messages: {
                required: "Please Select A Currency"
         }
	});
	listPrice.rules("add",{
		required:true,
		number:true,
		 messages: {
                required: "Please Enter A Price",
                number: "Value Entered Is Not Numeric"
         }
	});
	listPropertyType.rules("add",{
		required:true,
		 messages: {
                required: "Please Select A Property Type"
         }
	});

	listSubmit.click(function(e){
		if(listForm.valid() && spamFilter.val() === ""){			
			sendContact('List');
			e.preventDefault();
		}
	});

	$('#modal_button').click(function(){
		if($(this).text() === "Ok"){
			window.location.href = window.location.href;
		}
		else{
			$('#contact_modal').modal('hide');
		}
	});
}
/*************************************************************************/

/******Variables && Methods Exclusive To Find Me A Property Page *********/
var findForm = null,
	findTitle = null,
	findFirstName = null,
	findLastName = null,
	findEmail = null,	
	findCurrency = null,
	findPriceFrom = null,
	findPriceTo = null,
	findSubmit = null,
	findPropertyType = null;
function configureFindProperty(){
	spamFilter = $('#email_address_sent');
	searchLoadingDiv = $('#loading');
	mainContainer = $('#find_div');
	findForm = $('#find-property-form');
	findSubmit = $('#submit_find_request');
	findTitle = $('#FindForm_title');
	findFirstName = $('#FindForm_firstName');
	findLastName = $('#FindForm_lastName');
	findEmail = $('#FindForm_email');
	findCurrency = $('#FindForm_currency');
	findPriceFrom = $('#FindForm_priceFrom');
	findPriceTo = $('#FindForm_priceTo');
	findPropertyType = $('input[name="FindForm[propertyType][]"]');
	validator = findForm.validate();
	findForm.removeAttr("novalidate");
	
	findTitle.rules("add",{
		required:true,
		 messages: {
                required: "Please Select A Title"
         }
	});

	findFirstName.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your First Name"
         }
	});

	findLastName.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your Last Name"
         }
	});

	findEmail.rules("add",{
		 email:"required",
		 required:true,
		 messages: {
			 email: "Please Enter A Valid Email Address",
			 required:"Please Enter Your Email Address"
        }
	});

	findCurrency.rules("add",{
		required:true,
		 messages: {
                required: "Please Select A Currency"
         }
	});

	findPriceFrom.rules("add",{
		required:true,
		number:true,
		messages: {
			required: "Please Enter From Price Range",
			number: "Value Entered Is Not Numeric"
		}
	});

	findPriceTo.rules("add",{
		required:true,
		number:true,
		messages: {
			required: "Please Enter To Price Range",
			number: "Value Entered Is Not Numeric"
		}
	});

	findPropertyType.rules("add",{
		required:true,
		 messages: {
                required: "Please Select A Property Type"
         }
	});

	findSubmit.click(function(e){		
		if(findForm.valid() && spamFilter.val() === ""){			
			e.preventDefault();
			sendContact('Find');			
		}
	});
	
	$('#modal_button').click(function(){
		if($(this).text() === "Ok"){
			window.location.href = window.location.href;
		}
		else{
			$('#find_modal').modal('hide');
		}
	});
	
}
/************************************************************************/
/*-----------VIDEO ON DETAILS-------------*/

function callVideo() {
	// 2. This code loads the IFrame Player API code asynchronously.
	var tag = document.createElement('script');
	tag.src = "//www.youtube.com/iframe_api";
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}
// 3. This function creates an <iframe> (and YouTube player)
//    after the API code downloads.
var player;
function onYouTubeIframeAPIReady() {
	var video_prop_id = $('input[name="video_id"]').val(); 
	  
	  	
    player = new YT.Player('player', {
    	height: '390',
    	width: '640',
    	videoId: video_prop_id,
    	events: {
    		'onReady': onPlayerReady,
    		'onStateChange': onPlayerStateChange
    	}
    });
}
// 4. The API will call this function when the video player is ready.
function onPlayerReady(event) {
    event.target.playVideo();
}
// 5. The API calls this function when the player's state changes.
//    The function indicates that when playing a video (state=1),
//    the player should play for six seconds and then stop.
var done = false;
function onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.PLAYING && !done) {
		//setTimeout(stopVideo, 6000);
        done = true;
    }
}
function stopVideo() {
    player.stopVideo();
}
/*************************************************************************/

var loginEmail = null,
	loginPassword = null,
	loginSubmit = null,
	registerForm = null;

function configureMembers (){
	$('.site').css("background","url('images/symphony.png') repeat scroll 0 0 rgba(0, 0, 0, 0)");
	
	mainContainer = $('#member_div');
	localStorage.setItem('apiMember', null);
	loginEmail = $('#LoginForm_email');
	loginPassword = $('#LoginForm_password');
	loginSubmit = $('#submit_login_request');
	loginForm 		= $('#login-form');
	validator = loginForm.validate();

	loginEmail.rules("add",{
		required:true,
		 email:"required",
		 messages: {
	           email: "Please Enter A Valid Email Address",
	           required:"Please Enter Your Email Address"
	     }
	});

	loginPassword.rules("add",{
		required:true,
		 messages: {
                required: "Please Enter Your Password."
         }
	});

	loginSubmit.click(function(e){
		if(loginForm.valid()){			
			
		}
	});	
}

var registerTitle = null,
	registerFirstName = null,
	registerLastName = null,
	registerEmail = null,
	registerPassword = null,
	registerConfirmPassword = null,
	registerSubmit = null,
	registerForm = null,
	focusOutClass = null,
	getDuplicateAjaxRequest,
	duplicateCheck = null,
	mailingList = null,	
	mailingDiv = null,
	propertyTypes = null,
	mailingListTenure = null,
	messsageSpan;
function configureSignUp(){
	registerTitle = $('#RegisterForm_title');
	registerFirstName = $('#RegisterForm_firstName');
	registerLastName = $('#RegisterForm_lastName');
	registerEmail = $('#RegisterForm_email');
	registerPassword = $('#RegisterForm_password');
	registerConfirmPassword = $('#RegisterForm_confirmPassword');
	registerSubmit = $('#submit_register_request');
	registerForm = $('#register-form');
	focusOutClass = $('.focus_out');
	getDuplicateAjaxRequest = "";
	duplicateCheck = $('#duplicate_check');
	messsageSpan = $('#messsage_span');
	messsageSpan.hide();
	
	validator = registerForm.validate();

	registerEmail.rules("add",{
		required:true,
		email:"required",
		messages: {
			email: "Please Enter A Valid Email Address",
			required:"Please Enter Your Email Address"
		}
	});

	registerTitle.rules("add",{
		required:true,
		messages: {
			required: "Please Select a Title"
		}
	});

	registerFirstName.rules("add",{
		required:true,
		messages: {
			required: "Please Enter Your First Name"
		}
	});

	registerLastName.rules("add",{
		required:true,
		messages: {
			required: "Please Enter Your Last Name"
		}
	});	

	registerPassword.rules("add",{
		required:true,
		messages: {
			required: "Please Enter A Password."
		}
	});	

	registerConfirmPassword.rules("add",{
		required:true,
		equalTo:registerPassword,
		messages: {
			equalTo: "Password and Confirm Password Are Not The Same",
			required: "Please Enter Your Password Confirmation",
		}
	});	

	registerSubmit.click(function(e){
		if(registerForm.valid()){			
			if(duplicateCheck.val() === "2"){
				e.preventDefault();
			}
		}
	});

	focusOutClass.focusout(function(){
		getDuplicateUser();
	});	
}

function getDuplicateUser(){
	if( registerEmail.val() !== "" && registerFirstName.val() !== "" && registerLastName.val() !== "" ){
		if(getDuplicateAjaxRequest !== ""){
			getDuplicateAjaxRequest.abort()
		}
		var duplicateUrl = window.location.href+"/"+registerEmail.val()+"/"+registerFirstName.val()+"/"+registerLastName.val();		
		getDuplicateAjaxRequest = $.ajax({
								type:'GET',
								url: duplicateUrl,
								dataType: "json",								
								}
							);
		getDuplicateAjaxRequest.then(
			
			function(data){			
				if(data.status === 1){
					duplicateCheck.val(data.status);
					messsageSpan.html("");
					messsageSpan.removeClass('show');
					messsageSpan.hide();
				}
				else{					
					duplicateCheck.val(data.status);
					messsageSpan.html(data.message);
					console.log(data.status);
					if(data.status != null){
						messsageSpan.addClass('error-alert');
						messsageSpan.addClass('show');
					}				
				}	
			},
			function(data){
				
			}
		);		
	}	
}

function configureEditProfile(){
	registerTitle = $('#ProfileForm_title');
	registerFirstName = $('#ProfileForm_firstName');
	registerLastName = $('#ProfileForm_lastName');
	registerEmail = $('#ProfileForm_email');
	registerPassword = $('#ProfileForm_password');
	registerConfirmPassword = $('#ProfileForm_confirmPassword');
	registerSubmit = $('#complete_submit');
	registerForm = $('#list-property-form');
	focusOutClass = $('.focus_out');
	getDuplicateAjaxRequest = "";
	duplicateCheck = $('#duplicate_check');
	messsageSpan = $('#messsage_span');
	mailingList = $("input[name='ProfileForm[mailingList]']");
	propertyTypes = $("input[name='ProfileForm[propertyType][]']");
	mailingListTenure = $("input[name='ProfileForm[mailingListTenure]']")
	mailingDiv = $('#mailing_div');

	validator = registerForm.validate();

	registerEmail.rules("add",{
		required:true,
		email:"required",
		messages: {
			email: "Please Enter A Valid Email Address",
			required:"Please Enter Your Email Address"
		}
	});

	registerTitle.rules("add",{
		required:true,
		messages: {
			required: "Please Select a Title"
		}
	});

	registerFirstName.rules("add",{
		required:true,
		messages: {
			required: "Please Enter Your First Name"
		}
	});

	registerLastName.rules("add",{
		required:true,
		messages: {
			required: "Please Enter Your Last Name"
		}
	});

	registerConfirmPassword.rules("add",{
		equalTo:registerPassword,
		messages: {
			equalTo: "Password and Confirm Password Are Not The Same"
			
		}
	});	

	registerSubmit.click(function(e){
		if(registerForm.valid()){			
			if(duplicateCheck.val() === "2"){
				e.preventDefault();
			}
		}
	});

	focusOutClass.focusout(function(){
		getDuplicateUser();
	});	
	mailingList.change(function(){
		if(mailingList.is(':checked')) {
			var selected = $(this);
			if(selected.val() === "Y"){
				mailingDiv.show();
			}
			else if(selected.val() === "N"){
				mailingListTenure.prop("checked", false);
				propertyTypes.prop('checked', false);
				mailingDiv.hide();	
			}	
		}		
	});
}

function configureForgetPassword(){
	loginEmail = $('#ForgetForm_email');
	loginSubmit = $('#submit_forget_request');
	loginForm 		= $('#forget-form');
	validator = loginForm.validate();

	loginEmail.rules("add",{
		required:true,
		 email:"required",
		 messages: {
	           email: "Please Enter A Valid Email Address",
	           required:"Please Enter Your Email Address"
	     }
	});

	loginSubmit.click(function(e){
		if(loginForm.valid()){			
			
		}
	});
}

function configureResetPassword(){
	registerPassword = $('#ResetForm_password');
	registerConfirmPassword = $('#ResetForm_confirmPassword');	
	loginSubmit = $('#submit_reset_request');
	loginForm 		= $('#reset-form');
	validator = loginForm.validate();

	registerPassword.rules("add",{
		required:true,
		messages: {
			required: "Please Enter A Password."
		}
	});	

	registerConfirmPassword.rules("add",{
		required:true,
		equalTo:registerPassword,
		messages: {
			equalTo: "Password and Confirm Password Are Not The Same",
			required: "Please Enter Your Password Confirmation",
		}
	});

	loginSubmit.click(function(e){
		if(loginForm.valid()){			
			
		}
	});
}

var removeClass = null;

function configureFavouriteProperties(){
	var propertyId = null;
	var mlsFound = null;
	var currentRemove = null;
	
	mainPropertyContainer = $('#main_property_container');
	removeClass  = $('.remove_property');
	getCurrentPage = parseInt($('#current_page').val());	
	getAjaxRequest = "";
	searchLoadingDiv = $('#loading');
	configurePagination();
	pageUrl = ajaxLocation+"favourites";
	$(document).on('click', '.remove_property', function(e) {
		e.preventDefault();
		currentRemove = $(this);
		propertyId = currentRemove.data("property-id");
		mlsFound = currentRemove.data("mls");
		$('#modal_header').html("Confirm");
		$('#modal_paragraph').html("Are You Sure You Want To Remove This Favourite?");
		$('#modal_button').hide();
		$('#modal_button_yes').show();
		$('#modal_button_no').show();	
		
		$('#favourites_modal').modal('show');			
	});
	
	$('#modal_button_yes').click(function(){
		var favouriteUrl = ajaxLocation+"ajax-favourite/property_id/"+propertyId+"/mls/"+mlsFound+"/saved/1";
		savePropertyAjaxRequest = $.ajax({
							type:'POST',
							url: favouriteUrl,
							dataType: "json",									
						});
		savePropertyAjaxRequest.then(
			function(data){				
				if(data.status === "200"){				
					getAjaxRequest = 	favouritePagination(1,getAjaxRequest,false);						
					$('#modal_header').html("Removed");
					$('#modal_paragraph').html("Favourite Removed");
					$('#modal_button').show();
					$('#modal_button_yes').hide();
					$('#modal_button_no').hide();
					$('#favourites_modal').removeClass('failed');
				}
				else{
					$('#modal_header').html("Error");
					$('#modal_paragraph').html("Favourite Was Not Removed");
					$('#modal_button').show();
					$('#modal_button_yes').hide();
					$('#modal_button_no').hide();
					$('#favourites_modal').addClass('failed');
				}		
				
				$('#favourites_modal').modal('show');	
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);									
			},
			function(data){					
				$('#modal_header').html("Error");
				$('#modal_paragraph').html("Favourite Was Not Removed");
				
				$('#favourites_modal').addClass('failed');
				
				$('#modal_button').show();
				$('#modal_button_yes').hide();
				$('#modal_button_no').hide();
				
				$('#favourites_modal').modal('show');
				$('html,body').animate({scrollTop: $('body').offset().top}, 500);
			}
		);
		
	});
	$('#modal_button_no').click(function(){
		propertyId = "";
		mlsFound = "";
		$('#favourites_modal').modal('hide');
		$('.reveal-modal-bg').hide();
	});
	$('#modal_button').click(function(){
		$('#favourites_modal').modal('hide');
		$('.reveal-modal-bg').hide();
	});	
	
	
}

function favouritePagination(currentPage,ajaxRequest,popState){
	searchStartStopLoading(true);
	if(ajaxRequest !== ""){
		ajaxRequest.abort();
	}
	var favouriteUrl = ajaxLocation+"favourites/page/"+currentPage;
	var url = pageUrl+"/page/"+currentPage;
	ajaxRequest = 	$.ajax({
						type:'GET',
						url: favouriteUrl,
						dataType: "html"
					});
	ajaxRequest.then(
		function(data){			
			mainPropertyContainer.html(data);
			configurePagination();
			searchStartStopLoading(false);			
			pageLoaded = true;	
			if(!popState){
				history.pushState(url, "Favourite Properties", url);			
			}
			popStateCheck = 1;				
			var totalResults = $("#total_results_hidden").val();
            $("#total_results").text(totalResults);             
            $(document).foundation();
		},
		function(data){
		}
	); 
	return ajaxRequest;
}

function popStateFavouritePage(stateUrl){	
	if(stateUrl !== null){
		var urlSegments = stateUrl.split("favourites");
		var pageNumber  = 0;
		if(urlSegments[1].indexOf("/page/") !== -1){
			pageNumber = urlSegments[1].split("/page/");
			pageNumber = pageNumber[1];
			pageNumber = pageNumber.split("/");
			pageNumber = pageNumber[0];
		}		
		if(popStateCheck === 1){
			getAjaxRequest = favouritePagination(pageNumber,getAjaxRequest,true);				
		}
		popStateCheck = 1;	
	}
	else{		
		getAjaxRequest = favouritePagination(1,getAjaxRequest,true);
	}
}

/*************************************************************************/
/*Facebook API Membership Login 										 */
/*************************************************************************/

function configureFacebookAPI(){	
	(function(d, s, id) {
		var js, fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) return;
		js = d.createElement(s); js.id = id;
		js.src = "//connect.facebook.net/en_US/sdk.js";
		fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'facebook-jssdk'));

	window.fbAsyncInit = function() {
		FB.init({
			appId      : '316133481929964',  
			cookie     : true,  // enable cookies to allow the server to access 
			// the session
			xfbml      : true,  // parse social plugins on this page
			version    : 'v2.2' // use version 2.0
		});
		// Now that we've initialized the JavaScript SDK, we call 
	  	// FB.getLoginStatus().  This function gets the state of the
	  	// person visiting this page and can return one of three states to
	  	// the callback you provide.  They can be:
	  	//
	  	// 1. Logged into your app ('connected')
	  	// 2. Logged into Facebook, but not your app ('not_authorized')
	  	// 3. Not logged into Facebook and can't tell if they are logged into
	  	//    your app or not.
	  	//
	  	// These three cases are handled in the callback function.

	  	/*FB.getLoginStatus(function(response) {
	  		console.log(response);	  	
	  		if(response.status === "connected"){
	  			statusChangeCallback(response);	  			
	  		}	  			  		
	  	});
	  	*/
	};
}


function statusChangeCallback(response) {	    
    // The response object is returned with a status field that lets the
    // app know the current login status of the person.
    // Full docs on the response object can be found in the documentation
    // for FB.getLoginStatus().    
    if (response.status === 'connected') {    	
    	facebookAPILogin();
    } 
    
}

// This function is called when someone finishes with the Login
// Button.  See the onlogin handler attached to it in the sample
// code below.
function checkLoginState() {	
	FB.getLoginStatus(function(response) {
		/*if(response.status === "connected"){
			document.getElementById('fb_test_spig').style.display = 'none';
		}*/		
		statusChangeCallback(response);
	});
}

// Here we run a very simple test of the Graph API after login is
// successful.  See statusChangeCallback() for when this call is made.
function facebookAPILogin() {		
	FB.api('/me', function(response) {		
		if(!isEmptyObject(response)){			
			response.sent_api = "Facebook"; 
			var faceBookObject = response;
			response.image = 'http://graph.facebook.com/'+response.id+'/picture?type=large';			
			localStorage.setItem('apiMember', JSON.stringify(faceBookObject));				
			apiLogin(response);			
		}				
	});
}

function faceBookLogin(){	
	configureFacebookAPI();
    FB.login(function(response) {    	
    	statusChangeCallback(response);    	
    },{scope: 'email,user_photos'});
}
function googlePlusLogin(){
	gapi.auth.signIn({'clientid' : '831113217250-qf9472rb0tqies3ua3e5tr245t2km2ni.apps.googleusercontent.com','cookiepolicy' : 'single_host_origin','callback' : 'googlePlusSignInCallBack','scope': 'https://www.googleapis.com/auth/plus.login email'});
}
function googlePlusSignInCallBack(authResult){	
	if (authResult['status']['signed_in']) {	

        $.ajax({
            type: "GET",
            url: "https://www.googleapis.com/oauth2/v2/userinfo?access_token="+authResult['access_token']
        }).done(function( data ){        	
        	var apiObject = {};
        	apiObject.first_name = data.given_name;
        	apiObject.last_name = data.family_name;
        	apiObject.email = data.email;
        	apiObject.sent_api = 'Google +';
        	localStorage.setItem('apiMember', JSON.stringify(apiObject));       	
			apiLogin(apiObject);
        });
    }    	
}
function linkedInLogin(){	
	IN.User.authorize(function() {
		IN.API.Profile("me").fields("first-name", "last-name", "email-address","picture-url").result(function(result) {
			if(result.values.length){
				if(!isEmptyObject(result.values[0])){
					var apiObject = {};
					apiObject.first_name = result.values[0].firstName;
					apiObject.last_name = result.values[0].lastName;
					apiObject.email = result.values[0].emailAddress;
					apiObject.sent_api = 'LinkedIn';
					apiObject.image = result.values[0].pictureUrl;
					localStorage.setItem('apiMember', JSON.stringify(apiObject));       	
					apiLogin(apiObject);					
				}				
			}
			
			
			//alert(JSON.stringify(result));
		});
	});
	/*console.log('Test');;
    IN.Event.on(IN, "auth", OnLinkedInAuth);*/
}
function OnLinkedInAuth() {

    IN.API.Profile("me").result(ShowProfileData);
}
function ShowProfileData(profiles) {
	
    /*var member = profiles.values[0];
    var id=member.id;
    var firstName=member.firstName; 
    var lastName=member.lastName; 
    var photo=member.pictureUrl; 
    var headline=member.headline; */

    //use information captured above
}

function configureUnsubscribe(){
	registerForm = $('#unsubscribe-form');	
	registerEmail = $('#SubscribeForm_email');
	registerSubscribe = $('#SubscribeForm_subscribeEmail');	
	registerSubmit = $('#submit_contact_request');
	shareValidator = registerForm.validate();

	registerEmail.rules("add",{
		required:true,
		equalTo:registerSubscribe,
		 email:"required",
		 messages: {
		    email: "Please Enter Email Address",
		    required:"Please Enter Your Email Address",
		    equalTo: "Please enter the correct email address"
		}
	});
}

function configureUpdateProfile(){		
	registerForm = $('#update-profile-form');	
	registerFirstName = $('#UpdateProfileForm_firstName');
	registerLastName = $('#UpdateProfileForm_lastName');
	registerEmail = $('#UpdateProfileForm_email');	
	registerSubmit = $('#submit_contact_request');
	shareValidator = registerForm.validate();	

	registerEmail.rules("add",{
		required:true,		
		 email:"required",
		 messages: {
		    email: "Please Enter Email Address",
		    required:"Please Enter Your Email Address"		    
		}
	});

	registerFirstName.rules("add",{
		required:true,				 
		 messages: {		    
		    required:"Please Enter Your First Name"		    
		}
	});
	registerLastName.rules("add",{
		required:true,				 
		 messages: {		    
		    required:"Please Enter Your Last Name"		    
		}
	});
	
}